import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
} from "@mui/material";
import access from "../../../json/rbac.json";
import {
  MgpButton,
  MgpCheckbox,
  MgpTextboxFormik,
} from "../../../components/global";
import { useState } from "react";
import * as yup from "yup";
import { useRoles } from "../../../hooks";
import { Formik } from "formik";
import { colors } from "../../../theme/colors";
export const UpdateRoles = ({
  setAlertOption,
  setMainPageloading,
  setMessage,
  setAlertType,
  closed = () => {},
  edit = {},
}) => {
  const { role_id = "", role_name = "", access_rights = {} } = edit;
  const [access_right, setAccess] = useState(
    role_id === ""
      ? access.access_right
      : CompareChecks(access.access_right, access_rights)
  );
  const { useAddRoles, useUpdateRole } = useRoles();
  const [AddRoles] = useAddRoles;
  const [UpdateRole] = useUpdateRole;

  function CompareChecks(template, response) {
    let result = template;
    template.map((row, idx) => {
      let mAccess = result[idx].access;
      row.access.map((acc, idy) => {
        if (response[idx] && response[idx].access[idy]) {
          mAccess[idy].status = response[idx].access[idy].status;
        }
      });
      result[idx].access = mAccess;
    });

    return result;
  }
  function handleCheckChange(e) {
    const checks = Object.assign([], access_right);
    const new_access = checks.map((item) => {
      let newItem = Object.assign([], item);
      newItem.access = newItem.access.map((acc) =>
        item.code + acc.code !== e.target.id
          ? acc
          : { label: acc.label, code: acc.code, status: e.target.checked }
      );
      return newItem;
    });

    setAccess(new_access);
  }
  function handleCheckAll(state) {
    const checks = Object.assign([], access_right);
    const new_access = checks.map((item) => {
      let newItem = Object.assign([], item);
      newItem.access = newItem.access.map((acc) => ({
        label: acc.label,
        code: acc.code,
        status: state,
      }));
      return newItem;
    });
    setAccess(new_access);
  }
  console.log("access_right", access_right);
  return (
    <>
      <Container fluid="true">
        <Formik
          initialValues={{ role: role_name }}
          validationSchema={yup.object().shape({
            role: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
          })}
          onSubmit={(payload) => {
            let access = [];
            access_right.map((item) => {
              let single = {
                name: item.name,
                code: item.code,
                access: item.access,
              };
              access.push(single);
            });
            setMainPageloading(true);

            if (role_id === "") {
              AddRoles({
                variables: { roleName: payload.role, accessRights: access },
                onCompleted: () => {
                  setMainPageloading(false);
                  closed();
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("success");
                  setMessage("Roles Added successfully");
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message);
                  else setMessage("Something went wrong! Please try again");
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("error");
                },
              });
            } else {
              UpdateRole({
                variables: {
                  roleName: payload.role,
                  accessRights: access,
                  roleId: Number(role_id),
                },
                onCompleted: () => {
                  setMainPageloading(false);
                  closed();
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("success");
                  setMessage("Roles Updated successfully");
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message);
                  else setMessage("Something went wrong! Please try again");
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("error");
                },
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Role"
                    name="role"
                    splits={"role".split(".")}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                {access_right.map((config, idx) => {
                  return (
                    <Grid key={idx} item md={4}>
                      <FormControl component="fieldset">
                        {idx === 0 ||
                        (idx !== 0 &&
                          access_right[0].access.find(
                            (e) => e.code === config.code
                          )?.status === true) ? (
                          <>
                            <FormLabel component="legend">
                              {config.name}
                            </FormLabel>
                            <FormGroup>
                              {config.access &&
                                config.access.map((access, ix) => (
                                  <FormControlLabel
                                    key={ix}
                                    control={
                                      <MgpCheckbox
                                        size="small"
                                        onChange={handleCheckChange}
                                        checked={access.status}
                                        name={config.code + access.code}
                                        id={config.code + access.code}
                                      />
                                    }
                                    label={access.label}
                                    sx={{
                                      // color: colors.SECONDARY_TEXT,
                                      fontSize: "12px",
                                      "& .MuiFormControlLabel-label": {
                                        fontSize: "12px", // Ensure this targets the label specifically
                                      },
                                    }}
                                  />
                                ))}
                            </FormGroup>
                          </>
                        ) : null}
                      </FormControl>
                    </Grid>
                  );
                })}
                <Grid item md={12}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"center"}
                  >
                    <MgpButton
                      onClick={() => handleCheckAll(true)}
                      variant="contained"
                    >
                      Select All
                    </MgpButton>
                    <MgpButton
                      onClick={() => handleCheckAll(false)}
                      variant="contained"
                    >
                      De Select All
                    </MgpButton>
                    <MgpButton type="submit" variant="contained">
                      Save
                    </MgpButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};
