import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

// import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../theme/colors";
import { MgpTextbox } from "./MgpTextbox";
import { CustomInputLabel } from "./MgpToggleButtonGroup";

const MgpPaper = styled(Paper)(({ theme }) => ({
  "&.MuiPaper-root": {
    background: colors.PRIMARY_CONTRAST,
    height: 200,
    marginTop: 8,
    padding: 0,
  },
  "& .Mui-selected": { color: colors.GOLD, background: "#fff" },
  "& .MuiMenu-list": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },

  "& li": {
    padding: "3px 6px",
    display: "flex",
    gap: "10px",
    fontSize: "15px",
    fontWeight: "500",
    justifyContent: "start",
    alignItems: "center",
    "& span": {
      flexGrow: "2",
    },

    "& svg": {
      color: "currentColor",
      fontSize: "15px",
    },
  },
  "& li[aria-selected=`true`]": {
    backgroundColor: "#FFF",
    fontWweight: 300,
    color: `${colors.GOLD}`,
    "& svg": {
      color: `${colors.GOLD}`,
    },
  },
  "& li.focused": {
    backgroundColor: colors.GOLD,
    cursor: "pointer",
    "& svg": {
      color: colors.GOLD,
    },
  },
  "& li:hover": {
    color: colors.GOLD,
    cursor: "pointer",
    "& svg": {
      color: colors.GOLD,
    },
  },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected": {
    backgroundColor: "#FFF",
  },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:hover":
    {
      backgroundColor: "#F5F5F5",
    },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:focus":
    {
      backgroundColor: "#F5F5F5",
    },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:active":
    {
      backgroundColor: "#FFF",
    },
}));
export const MgpSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "white", // Set background color to white
  borderRadius: "5px", // Optional: Add corner radius

  "& .MuiInputBase-root": {
    fontSize: "20px !important", // Set font size
    backgroundColor: "white", // Ensure the input background is white
    borderRadius: "5px", // Maintain corner radius
    height: "30px", // Set height for input base
  },

  "& .MuiFormControl-root": {
    // Style for FormControl if needed
    height: "30px", // Maintain height for FormControl
  },

  "& .MuiFormLabel-root": {
    color: "red !important", // Set label color to red
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Set border color to white
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Keep border color white on hover
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Keep border color white when focused
  },

  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Set border color to white when disabled
  },
  "& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    fontSize: "20px !important",
  },
  "& .MuiFormControl-root .MuiFormLabel-root-MuiInputLabel-root": {
    color: "red !important",
  },
}));

export const DropDownCrf = ({
  splits,
  name,
  label,
  multiselect = true,
  options,
  touched,
  setFieldValue,
  errors,
  error,
  values,
  handleBlur,
  disabled,
  required,
  dependent,
  setSelectedcountryid,
  keyName,
  value = "",
  helperText,
  size,
  dependenValue,
  selectedValue,
  selectedId,
  viewMode,
  fieldValue,
  removeDependence,
  checkfieldvalue,
  setValues,
  commentData,
  encountedDataLatestStatus,
  edit,
  parentName,
  parentType,
  selectedcity,
  edit_patientform,
  filter,
  updatethevalue,
  serotype,
  setserotype,
  setnotemessage,
  handleChange,
  setdefaultvalue,
  attribute,
  removeoptionsdependent,
  removeFiledsFromDropDown,
  optionsAfterRemoveing,
}) => {
  if (multiselect) {
    if (values?.[name]) {
      if (values?.[name].length > 0) {
        if (
          (Array.isArray(values?.[name]) &&
            Array.isArray(values?.[name]).length > 0 &&
            values?.[name]?.[0] == null) ||
          values?.[name]?.[0] === undefined ||
          values?.[name]?.[0] === ""
        ) {
          setFieldValue(
            name,
            values?.[name]?.filter(
              (item) => item !== null && item !== undefined && item !== ""
            )
          );
        }
      }
    }
  }

  const containsText = (text, searchText) =>
    text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;
  const [selectedOption, setSelectedOption] = useState([]);
  const handleDelete = (valueToDelete) => () => {
    setSelectedOption((prevSelected) =>
      prevSelected.filter((value) => value !== valueToDelete)
    );
  };

  const [searchText, setSearchText] = useState("");

  const displayedOptions = useMemo(() => {
    // if (selectedcity) {
    //   const updatedValues = {};
    //   updatedValues[name] = e.target.value;
    //   const cityObject = options.find(
    //     (city) => city.city_name === e.target.value
    //   );

    //   const regionName = cityObject ? cityObject.region_name : null;

    //   updatedValues[selectedcity] = regionName;

    //   setValues((prevValues) => ({
    //     ...prevValues,
    //     ...updatedValues,
    //   }));

    if (keyName) {
      return options.length > 0
        ? options.filter((option) => containsText(option[keyName], searchText))
        : [];
    } else {
      if (Array.isArray(options?.[0])) {
        const valuess = values?.[dependent];

        if (fieldValue && valuess) {
          const foundIndex = fieldValue.findIndex((elems) => elems === valuess);
          if (foundIndex !== -1) {
            return options[foundIndex].filter((option) =>
              containsText(option, searchText)
            );
          }
        }
        return options?.[0].filter((option) =>
          containsText(option, searchText)
        );
      } else {
        const hasStrings = options?.some((item) => typeof item === "string");
        if (hasStrings) {
          return options?.filter((option) => containsText(option, searchText));
        } else {
          if (filter) {
            if (values?.[filter]) {
              const filtercityNames = options?.filter(
                (city) => values?.[filter] === city?.["region_name"]
              );
              const cityNames = filtercityNames.map(
                (city) => city?.[selectedcity]
              );
              return cityNames.filter((option) =>
                containsText(option, searchText)
              );
            } else {
              const cityNames = options?.map((city) => city?.[selectedcity]);
              return cityNames.filter((option) =>
                containsText(option, searchText)
              );
            }
          } else {
            const cityNames = options?.map((city) => city?.[selectedcity]);
            return cityNames?.filter((option) =>
              containsText(option, searchText)
            );
          }
        }
      }
    }
  }, [options, values, dependent, searchText]);
  useEffect(() => {
    if (keyName) {
      const selectedRole = displayedOptions.find(
        (role) => selectedValue === parseInt(role[selectedId])
      );
      if (selectedRole) {
        setSelectedOption([selectedRole[keyName]]);
      }
    }
  }, [displayedOptions]);

  const hasAsterisk = label?.endsWith("*");

  return (
    <>
      <CustomInputLabel
        id={name}
        style={{
          marginBottom: "5px",
          color: error ? colors.ERROR : colors.CFRBLUE,
          fontFamily: "'Poppins', sans-serif",
          fontSize: "13px",
          fontWeight: "normal",
          whiteSpace: "normal",
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {hasAsterisk ? (
          <>
            {label?.slice(0, -1)}
            <span className="red-asterisk">*</span>
          </>
        ) : (
          label
        )}
      </CustomInputLabel>
      <FormControl style={{ width: "100%" }} size={size} error={error}>
        <MgpSelect
          labelId={name + "label"}
          MenuProps={{
            autoFocus: false,
            PaperProps: {
              component: MgpPaper,
            },
          }}
          aria-invalid="false"
          id={name}
          value={
            multiselect
              ? Array.isArray(values?.[name])
                ? values?.[name]?.filter(
                    (item) => item !== null && item !== undefined && item !== ""
                  )
                : []
              : values?.[name] === ""
              ? ""
              : values?.[name] || ""
          }
          label={label}
          name={name}
          disabled={
            edit_patientform && !disabled
              ? false
              : (parentType === "Accordion" &&
                  (encountedDataLatestStatus === 4 ||
                    encountedDataLatestStatus === 6) &&
                  edit) ||
                encountedDataLatestStatus === 4 ||
                encountedDataLatestStatus === 6
              ? !edit
                ? true
                : disabled
              : disabled || viewMode
              ? true
              : false
          }
          onChange={(e) => {
            handleChange(name, attribute);
            if (removeFiledsFromDropDown) {
              if (e.target.value) {
                if (Array.isArray(e.target.value)) {
                  optionsAfterRemoveing.forEach((key) => {
                    if (
                      Array.isArray(e.target.value) &&
                      e.target.value.includes(key)
                    ) {
                      if (removeDependence) {
                        const updatedValues = {};
                        updatedValues[name] = [key];
                        // console.log("details", checkfieldvalue, value);

                        removeDependence.forEach((nameToRemove) => {
                          updatedValues[nameToRemove] = "";
                        });
                        setdefaultvalue?.forEach((defaultvalue) => {
                          updatedValues[defaultvalue] = false;
                        });
                        // Update the state outside the rendering phase
                        setValues((prevValues) => ({
                          ...prevValues,
                          ...updatedValues,
                        }));
                        // setFieldValue(name, [key]);
                      }
                    } else {
                      const { value } = e.target;

                      setFieldValue(name, value);
                    }
                  });
                }
              }
            } else {
              if (
                serotype !== undefined &&
                values?.[serotype] === "Sero -" &&
                e.target.value === "Positive"
              ) {
                setserotype(true);
                setnotemessage(
                  `If Sero- is selected for “Type of MG” under Diagnosis section, then underlying results for Serological Tests should be either Negative or Not Done`
                );
              } else {
                if (updatethevalue) {
                  const updatedValues = {};
                  updatedValues[name] = e.target.value;
                  updatedValues[updatethevalue] = "";
                  setValues((prevValues) => ({
                    ...prevValues,
                    ...updatedValues,
                  }));
                } else {
                  const { value } = e.target;
                  if (typeof value !== "string") {
                    const filteredArray = value.filter(
                      (item) =>
                        item !== null && item !== undefined && item !== ""
                    );
                    setFieldValue(name, filteredArray);
                    if (removeDependence) {
                      const includesArray2InArray1 = checkfieldvalue.some(
                        (element) => filteredArray.includes(element)
                      );

                      if (!includesArray2InArray1) {
                        const updatedValues = {};
                        updatedValues[name] = filteredArray;
                        removeDependence.forEach((nameToRemove) => {
                          updatedValues[nameToRemove] = "";
                        });

                        setValues((prevValues) => ({
                          ...prevValues,
                          ...updatedValues,
                        }));
                      }
                    }
                  } else {
                    if (
                      removeDependence &&
                      (values?.[name] !== "" || values?.[name] !== undefined)
                    ) {
                      if (checkfieldvalue.includes(value)) {
                        const updatedValues = {};
                        updatedValues[name] = value;

                        removeDependence.forEach((nameToRemove) => {
                          updatedValues[nameToRemove] = "";
                        });
                        setdefaultvalue?.forEach((defaultvalue) => {
                          updatedValues[defaultvalue] = false;
                        });
                        // Update the state outside the rendering phase
                        setValues((prevValues) => ({
                          ...prevValues,
                          ...updatedValues,
                        }));
                      } else {
                        setFieldValue(name, value);
                      }
                    } else {
                      setFieldValue(name, value);
                    }
                  }
                }
              }
            }
          }}
          onClose={() => setSearchText("")}
          onBlur={handleBlur}
          displayEmpty
          // renderValue={(selected) => <Box sx={{whiteSpace:"wrap"}}>{renderValue(selected)}</Box>}
          renderValue={(selected) => {
            // For multiselect, handle empty selection
            if (multiselect) {
              // Check if selected array is empty or contains only an empty string
              if (
                !selected.length ||
                (selected.length === 1 && selected[0] === "") ||
                (selected.length === 1 && selected[0] === undefined) ||
                (selected.length === 1 && selected[0] === null)
              ) {
                return (
                  <span style={{ color: colors.GRAY }}>
                    Please Select Options
                  </span>
                );
              }

              return (
                <Box sx={{ whiteSpace: "wrap" }}>
                  {selected.filter((option) => option !== "").join(", ")}
                </Box>
              );
            }

            return selected && selected !== "" ? (
              <Box
                sx={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selected}
              </Box>
            ) : (
              <span style={{ color: colors.GRAY }}>Please Select Options</span>
            );
          }}
          multiple={multiselect}
          style={{ fontSize: "15px", paddingTop: "6px" }}
        >
          <ListSubheader>
            <MgpTextbox
              size="small"
              name={name}
              id={name}
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputLabelProps={{
                style: {
                  // fontFamily: "Montserrat",
                  fontSize: "15px !important",
                  fontWeight: "400",
                  paddingTop: "2px",
                  lineHeight: "28p",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ width: "21px", height: "22px" }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((item) => {
            return (
              <MenuItem
                key={keyName ? item[keyName] : item}
                value={keyName ? item[keyName] : item}
                id={name} // Add id attribute
                name={name} // Add name attribute
                onClick={() => {
                  if (values[name] && item === values[name]) {
                    const updatedValues = {};
                    updatedValues[name] = "";

                    updatedValues[selectedcity] = "";

                    setValues((prevValues) => ({
                      ...prevValues,
                      ...updatedValues,
                    }));
                  } else {
                    setFieldValue(name, "");
                  }
                }}
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <CheckCircleOutlineRoundedIcon fontSize="small" />
                <p
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {keyName ? item[keyName] : item}
                </p>
              </MenuItem>
            );
          })}
        </MgpSelect>
        {/* {error && <FormHelperText>{helperText}</FormHelperText>} */}
      </FormControl>
    </>
  );
};
