import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  MgpButton,
  MgpText,
  MgpModal,
  MgpTextboxFormik,
  MgpTextbox,
} from "../components/global";
import { MgpBackdrop } from "../components/global/MgpBackdrop";
import { Container, Grid } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { usePatient, useRbac } from "../hooks";
import MgpAlertBar from "../components/global/MgpAlertBar";
import { StorageHelper } from "../helpers";
import { Stack } from "@mui/system";

export const VerifyPatient = () => {
  const navigate = useNavigate();
  const { useNationalIdCheck } = usePatient();
  const [IsNationalIdExists, { data, loading, error }] = useNationalIdCheck;
  const handleSubmitClose = () => {
    setSubmitOpen(false);
  };
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertOption, setAlertOption] = React.useState(false);
  const [submitOpen, setSubmitOpen] = React.useState(false);
  const verifyPatient = () => {
    if (nationalID.national !== nationalID.confirmNational) {
      setNationaID({ ...nationalID, errors: "National ID not matching" });
    } else {
      handleOpenBackdrop();
    }
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const [nationalID, setNationaID] = React.useState({
    national: "",
    confirmNational: "",
    errors: "",
  });

  const handleSubmitCloseAlert = () => {
    setAlertOption(false);
    setMessage("");
  };
  const handleChange = (e) => {
    setNationaID({
      ...nationalID,
      [e.target.name]: e.target.value,
      errors: "",
    });
  };
  const handlePaste = (event) => {
    // For preventing copy paste
    event.preventDefault();
  };
  return (
    <Container fluid="true">
      <Formik
        initialValues={{
          national: "",
          confirmNational: "",
        }}
        validationSchema={yup.object().shape({
          national: yup
            .string()
            .min(10, "National ID must be 10 characters")
            .max(10, "National ID must be 10 characters")
            .test(
              "first-letter",
              "First letter of National ID should be 1 or 2",
              (value) => value && /^[1-2]/.test(value)
            )
            .required("Required"),
          confirmNational: yup
            .string()
            .oneOf([yup.ref("national")], "National IDs should match")
            .required("Required"),
        })}
        onSubmit={(payload) => {
          handleOpenBackdrop();
          IsNationalIdExists({
            variables: { nationalId: payload["confirmNational"].toString() },
            onCompleted: (data) => {
              if (data?.IsNationalIdExists?.patient_id == -1) {
                setMessage(
                  "National ID is not a valid number. Please check again!"
                );
                setAlertOption(true);
                handleCloseBackdrop();
              } else if (data?.IsNationalIdExists) {
                const loggedUser = JSON.parse(
                  StorageHelper.get("user")
                )?.user_id;
                setMessage("National ID already registered.");
                setAlertOption(true);
                handleCloseBackdrop();
                if (data?.IsNationalIdExists?.user_id == loggedUser) {
                  // If the national id is registred with the logged in user then redirecting to visit history page
                  navigate(
                    `/app/patients/viewPatient/${data?.IsNationalIdExists?.patient_id}`
                  );
                }
              } else {
                navigate(
                  `/app/patients/newPatient/${parseInt(
                    payload["confirmNational"]
                  )}`
                );
              }
            },
          });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <MgpTextbox
                  fullWidth
                  size="small"
                  label="National ID"
                  name="national"
                  value={values["national"]}
                  inputProps={{
                    maxLength: 10,
                    fontSize: "13px",
                    fontWeight: "400"
                  }}
                  onChange={(e) => {
                    let newValue = e.target.value;

                    if (/^[12]/.test(newValue.charAt(0))) {
                      newValue = newValue.replace(/\D/g, "");
                      if (/^\d*$/.test(newValue.charAt(1))) {
                        setFieldValue("national", newValue);
                      } else {
                        newValue = newValue.slice(0, 1);
                        setFieldValue("national", newValue);
                      }
                    } else {
                      newValue = "";
                      setFieldValue("national", newValue);
                    }
                  }}
                  onPaste={handlePaste}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  variant="outlined"
                  error={touched["national"] && !!errors["national"]}
                  helperText={touched["national"] && errors["national"]}
                />
              </Grid>
              <Grid item md={12}>
                <MgpTextbox
                  fullWidth
                  size="small"
                  label="Confirm National ID"
                  name="confirmNational"
                  value={values["confirmNational"]}
                  onPaste={handlePaste}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (/^[12]/.test(newValue.charAt(0))) {
                      newValue = newValue.replace(/\D/g, "");
                      if (/^\d*$/.test(newValue.charAt(1))) {
                        setFieldValue("confirmNational", newValue);
                      } else {
                        newValue = newValue.slice(0, 1);
                        setFieldValue("confirmNational", newValue);
                      }
                    } else {
                      newValue = "";
                      setFieldValue("confirmNational", newValue);
                    }
                  }}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: 10,
                    fontSize: "13px",
                    fontWeight: "400"
                  }}
                  setFieldValue={setFieldValue}
                  variant="outlined"
                  error={
                    touched["confirmNational"] && !!errors["confirmNational"]
                  }
                  helperText={
                    touched["confirmNational"] && errors["confirmNational"]
                  }
                />
              </Grid>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                <Stack spacing={2} justifyContent={"center"} direction={"row"}>
                  <MgpButton
                    disabled={
                      values["national"] === "" ||
                      values["confirmNational"] === ""
                    }
                    type="submit"
                    variant="contained"
                    fullWidth
                  >
                    Continue to Register
                  </MgpButton>
                </Stack>
              </Grid>
              <Grid item md={3}></Grid>
              {openBackdrop && (
                <Grid item md={12}>
                  <MgpBackdrop
                    openBackdrop={openBackdrop}
                    message={"Verifying..."}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitCloseAlert}
        alertType="error"
        message={message}
      />
    </Container>
  );
};
