import {
  Container,
  Grid,
  Stack,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";
import { Uploader } from "rsuite";
import * as Yup from "yup";
import {
  MgpButton,
  MgpDD,
  MgpTextbox,
  MgpTextboxFormik,
} from "../../components/global";
import MgpAlertBar from "../../components/global/MgpAlertBar.js";
import { MgpDatePicker } from "../../components/global/MgpDatePicker";
import { ENV } from "../../helpers/configs";
import { useMaster, usePatient, useTransfer } from "../../hooks";
import { useHospital } from "../../hooks/useHospital";
import { useUser } from "../../hooks/useUsers";
import { colors } from "../../theme/colors";
import SearchIcon from "@mui/icons-material/Search";
import { StorageHelper } from "../../helpers/storageHelper.js";
import { MgpDatePickerOther } from "../../components/global/MgpDatePickerOther.js";

export const TransferForm = ({ edit = {}, setFieldValue, closed }) => {
  const user = JSON.parse(StorageHelper.get("user"));
  const { useNationalIdCheck } = usePatient();
  const [IsNationalIdExists, { data, loading, error }] = useNationalIdCheck;
  const { useGetPatient, useGetTransferStatus } = useTransfer();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [
    PatientTransferStatus,
    { data: statusData, loading: loadingStatus, error: errorStatus },
  ] = useGetTransferStatus;

  const { useGetDoctorUser } = useUser();
  const [
    GetDoctors,
    { data: doctorData, loading: loadingDoctorList, error: errorDoctorList },
  ] = useGetDoctorUser;
  const filteredDoctors = doctorData?.GetDoctors.filter(
    (doc) => parseInt(doc?.value) !== parseInt(user.user_id)
  );
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [nationalId, setNationalId] = useState("");
  const [alertOption, setAlertOption] = useState(false);
  const [alertPending, setAlertPending] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [initialValues, setInitialValues] = useState({
    national_id: "",
    patient_name: "",
    patient_id: "",
    current_hospital_id: "",
    current_doctor_id: "",
    current_hospital: "",
    current_doctor: "",
    enrollment_date: "",
    reason_transfer: "",
    transfer_doctor_id: "",
    transfer_hospital_id: "",
    consent_date: "",
    verbal_consent: "",
    file_path: "",
    user_id: "",
    user_name: "",
    user_hospital: "",
    region_name: "",
    city_name: "",
    approved_on: "",
  });
  const { hospitals } = useMaster();
  const uploaderRef = React.useRef(null);

  const validationSchema = Yup.object({
    national_id: Yup.string().required("National Id is required"),
    // patient_name: Yup.string().required('Patient name is required'),
    reason: Yup.string().required("Reason for transfer is required"),
    // requested_by: Yup.string().required('Unable to fetch current doctor'),
    transfer_doctor: Yup.string().required("Transfer doctor name is required"),
    transfer_hospital: Yup.string().required(
      "Transfer hospital name is required"
    ),
  });

  const handleReset = (resetForm) => {
    resetForm({ ...initialValues, requested_by: edit.requested_by || "" });
  };
  const { useAddTransfer } = useTransfer();
  const [
    AddTransferRequest,
    {
      data: transferAddData,
      loading: transferAddLoading,
      error: errorAddTransfer,
    },
  ] = useAddTransfer;
  const handleSubmitData = (values) => {
    setLoadingSubmit(true);
    AddTransferRequest({
      variables: {
        national_id: values["national_id"].toString(),
        patient_id: Number(values["patient_id"]),
        current_doctor_id: Number(values["current_doctor_id"]),
        current_hospital_id: Number(values["current_hospital_id"]),
        reason_transfer: values["reason_transfer"],
        transfer_hospital_id: Number(values["transfer_hospital_id"]),
        transfer_doctor_id: Number(values["transfer_doctor_id"]),
        file_path: values["file_path"],
        consent_date: values["consent_date"],
        verbal_consent: values["verbal_consent"] == "true" ? true : false,
      },
      onCompleted: (data) => {
        setAlertOption(true);
        setTimeout(() => {
          setLoadingSubmit(false);
          setAlertOption(false);
          closed();
        }, 3000);
      },
      onError: (err) => {
        console.log("The error happening in AddTransfer Request is", err);
      },
    });
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  const fetchPatientDetails = (val, setFieldValue, values) => {
    if (val) {
      setLoadingSubmit(true);
      IsNationalIdExists({
        variables: { nationalId: val.toString() },
        onCompleted: (data) => {
          if (data?.IsNationalIdExists?.patient_id == -1) {
            setAlertMessage(
              "National ID is not a valid number. Please check again!"
            );
            setAlertPending(true);
            setTimeout(() => {
              setAlertPending(false);
              // closed()
            }, 5000);
          } else if (data?.IsNationalIdExists) {
            PatientTransferStatus({
              variables: {
                patientId: parseInt(data.IsNationalIdExists?.patient_id),
              },
              onCompleted: (dataStatus) => {
                if (
                  parseInt(
                    dataStatus?.PatientTransferStatus?.transfer_status
                  ) !== 1 &&
                  parseInt(
                    dataStatus?.PatientTransferStatus?.transfer_status
                  ) !== 4
                ) {
                  setFieldValue(
                    "patient_name",
                    dataStatus?.PatientTransferStatus?.patient_name
                  );
                  setFieldValue(
                    "current_doctor",
                    dataStatus?.PatientTransferStatus?.current_doctor
                  );
                  setFieldValue(
                    "current_hospital",
                    dataStatus?.PatientTransferStatus?.current_hospital
                  );
                  setFieldValue(
                    "enrollment_date",
                    dataStatus?.PatientTransferStatus?.enrollment_date
                  );
                  setFieldValue(
                    "approved_on",
                    dataStatus?.PatientTransferStatus?.approved_on
                  );
                  setFieldValue(
                    "patient_id",
                    dataStatus?.PatientTransferStatus?.patient_id
                  );
                  setFieldValue(
                    "current_doctor_id",
                    dataStatus?.PatientTransferStatus?.doctor_id
                  );
                  setFieldValue(
                    "current_hospital_id",
                    dataStatus?.PatientTransferStatus?.hospital_id
                  );
                  setFieldValue(
                    "user_id",
                    dataStatus?.PatientTransferStatus?.user_id
                  );
                  setAlertMessage(
                    `Your transfer request for ${dataStatus?.PatientTransferStatus?.patient_registry_no} has been submitted successfully`
                  );
                  if (
                    dataStatus?.PatientTransferStatus?.user_id !==
                    dataStatus?.PatientTransferStatus?.doctor_id
                  ) {
                    setFieldValue(
                      "transfer_doctor_id",
                      dataStatus?.PatientTransferStatus?.user_id
                    );

                    const user_hospital = JSON.parse(
                      StorageHelper.get("assigned_hospitals")
                    );
                    setFieldValue(
                      "transfer_hospital_id",
                      parseInt(user_hospital[0])
                    );
                    setFieldValue(
                      "transfer_doctor",
                      dataStatus?.PatientTransferStatus?.user_name
                    );
                    setFieldValue(
                      "transfer_hospital",
                      dataStatus?.PatientTransferStatus?.user_hospital
                    );
                    let data = hospitals.find(
                      (elem) =>
                        elem.value ==
                        dataStatus?.PatientTransferStatus?.hospital_id
                    );
                    setFieldValue("region_name", data?.region_name);
                    setFieldValue("city_name", data?.city_name);
                  }
                } else {
                  if (
                    parseInt(
                      dataStatus?.PatientTransferStatus?.transfer_status
                    ) === 4
                  ) {
                    setAlertMessage("Patient transfer cannot be initiated");
                  } else setAlertMessage("Patient transfer already initiated!");
                  setAlertPending(true);
                  setTimeout(() => {
                    setAlertPending(false);
                    // closed()
                  }, 5000);
                }
              },
            });

            handleCloseBackdrop();
          } else {
            setAlertMessage("National ID not exist");
            setAlertPending(true);
            setTimeout(() => {
              setAlertPending(false);
              // closed()
            }, 5000);
          }
          setTimeout(() => {
            setLoadingSubmit(false);
            // closed()
          }, 5000);
        },
      });
    }
  };
  const handleChangeNationalId = (val, setFieldValue, values) => {
    handleOpenBackdrop();
    setTimeout(() => {
      fetchPatientDetails(val, setFieldValue, values);
    }, 500);
    setFieldValue("national_id", val);
  };
  // const {handleFileUpload} = useFileUpload();
  const handleUpload = async (files) => {
    // Currently not using directly calling in Uploader Action
    try {
      const formData = new FormData();
      formData.append("file", files);
      const response = await axios.post(`${ENV.file_upload_url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log("The error happened in file upload is", err);
    }
  };

  return (
    <>
      <Container fluid>
        {/* <Padding size='10px 0px 10px'>
                <MgpText variant="h4">Transfer Patient Form</MgpText>
            </Padding> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          // onSubmit={handleSubmit}
        >
          {({
            error,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            value,
            values,
            errors,
            resetForm,
          }) => (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="National ID"
                    name="national_id"
                    InputLabelProps={{
                      sx: {
                        // fontFamily: "Montserrat",
                        fontSize: "13px",
                        fontWeight: "400",
                        paddingTop: "2px",
                        lineHeight: "28p",
                      },
                    }}
                    inputProps={{
                      sx: {
                        marginY: "2.5px",
                        fontSize: "13px",
                      },
                      fontSize: "13px",
                      fontWeight: "400",
                      maxLength: 10,
                      // paddingTop: "2px",
                      // lineHeight: "28p",
                    }}
                    defaultValue={values}
                    value={values.national_id}
                    onChange={(e) => {
                      setFieldValue("patient_name", "");
                      setFieldValue("current_doctor", "");
                      setFieldValue("current_hospital", "");
                      setFieldValue("enrollment_date", "");
                      setFieldValue("patient_id", "");
                      setFieldValue("current_doctor_id", "");
                      setFieldValue("current_hospital_id", "");
                      setFieldValue("transfer_doctor_id", "");
                      setFieldValue("transfer_hospital_id", "");
                      setFieldValue("transfer_doctor", "");
                      setFieldValue("transfer_hospital", "");
                      setFieldValue("region_name", "");
                      setFieldValue("city_name", "");
                      setFieldValue("national_id", e.target.value);
                      if (e.target.value.length === 10) {
                        fetchPatientDetails(
                          e.target.value,
                          setFieldValue,
                          values
                        );
                      }
                    }}
                    sx={{
                      fontSize: 12,
                    }}
                    setFieldValue={setFieldValue}
                    variant="outlined"
                    touched={touched}
                    error={error}
                    // InputProps={{
                    //     endAdornment: (
                    //       <InputAdornment position="end">
                    //         <IconButton onClick={()=>{
                    //              fetchPatientDetails(
                    //                 values.national_id,
                    //                 setFieldValue,
                    //                 values
                    //               );
                    //         }}>
                    //           <SearchIcon />
                    //         </IconButton>
                    //       </InputAdornment>
                    //     ),
                    //   }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Patient Name"
                    name="patient_name"
                    InputLabelProps={{
                      sx: {
                        // fontFamily: "Montserrat",
                        fontSize: "13px",
                        fontWeight: "400",
                        paddingTop: "2px",
                        lineHeight: "28p",
                      },
                    }}
                    inputProps={{
                      sx: {
                        marginY: "2.5px",
                        fontSize: "13px",
                      },
                      fontSize: "13px",
                      fontWeight: "400",
                      // paddingTop: "2px",
                      // lineHeight: "28p",
                    }}
                    value={values.patient_name}
                    disabled
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={error}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Current Hospital"
                    name="current_hospital"
                    splits={"current_hospital".split(".")}
                    values={values}
                    disabled
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Current Doctor"
                    name="current_doctor"
                    splits={"current_doctor".split(".")}
                    values={values}
                    disabled
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Enrollment Date"
                    name="enrollment_date"
                    splits={"enrollment_date".split(".")}
                    values={values}
                    disabled
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpDatePickerOther
                    size="small"
                    fullWidth
                    onChange={setFieldValue}
                    errors={Boolean(
                      errors?.["consent_date"] && touched?.["consent_date"]
                    )}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    label={"Date of signed request"}
                    name={"consent_date"}
                    values={values}
                    handleBlur={handleBlur}
                    startDate={
                      values["approved_on"] !== ""
                        ? "approved_on"
                        : "enrollment_date"
                    }
                    disablePast={false}
                    monthly={true}
                    disableFuture={true}
                    restrictto={false}
                    showtodayDate={true}
                    variant="outlined"
                    disabled={values["enrollment_date"] === ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {values["user_id"] != values["current_doctor_id"] ? (
                    <MgpTextboxFormik
                      fullWidth
                      size="small"
                      label="Transfer Hospital"
                      name="transfer_hospital"
                      splits={"transfer_hospital".split(".")}
                      values={values}
                      disabled
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      variant="outlined"
                      touched={touched}
                      errors={errors}
                    />
                  ) : (
                    <MgpDD
                      label="Transfer Hospital"
                      variant="outlined"
                      size="small"
                      name="transfer_hospital_id"
                      error={Boolean(
                        touched["transfer_hospital_id"] &&
                          errors["transfer_hospital_id"]
                      )}
                      fullWidth
                      onChange={(e) => {
                        handleChange(e);
                        let data = hospitals.find(
                          (elem) => elem.value == e.target.value
                        );
                        setFieldValue("region_name", data?.region_name);
                        setFieldValue("city_name", data?.city_name);
                        GetDoctors({
                          variables: { hospitalId: Number(e.target.value) },
                          onCompleted: ({ GetDoctors: doctorData }) => {
                            // handleChange(e);
                          },
                        });
                      }}
                      options={hospitals}
                      helperText={
                        touched["transfer_hospital_id"] &&
                        errors["transfer_hospital_id"]
                      }
                      onBlur={handleBlur}
                      value={values["transfer_hospital_id"]}
                      multiselect={false}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Region Name"
                    name="region_name"
                    InputLabelProps={{
                      sx: {
                        // fontFamily: "Montserrat",
                        fontSize: "13px",
                        fontWeight: "400",
                        paddingTop: "2px",
                        lineHeight: "28p",
                      },
                    }}
                    inputProps={{
                      sx: {
                        marginY: "2.5px",
                        fontSize: "13px",
                      },
                      fontSize: "13px",
                      fontWeight: "400",
                      // paddingTop: "2px",
                      // lineHeight: "28p",
                    }}
                    value={values.region_name}
                    disabled
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={error}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="City Name"
                    name="city_name"
                    InputLabelProps={{
                      sx: {
                        // fontFamily: "Montserrat",
                        fontSize: "13px",
                        fontWeight: "400",
                        paddingTop: "2px",
                        lineHeight: "28p",
                      },
                    }}
                    inputProps={{
                      sx: {
                        marginY: "2.5px",
                        fontSize: "13px",
                      },
                      fontSize: "13px",
                      fontWeight: "400",
                      // paddingTop: "2px",
                      // lineHeight: "28p",
                    }}
                    value={values.city_name}
                    disabled
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={error}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {values["user_id"] != values["current_doctor_id"] ? (
                    <MgpTextboxFormik
                      fullWidth
                      size="small"
                      label="Transfer Doctor"
                      name="transfer_doctor"
                      splits={"transfer_doctor".split(".")}
                      values={values}
                      disabled
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      variant="outlined"
                      touched={touched}
                      errors={errors}
                    />
                  ) : (
                    <MgpDD
                      label="Transfer Doctor"
                      variant="outlined"
                      size="small"
                      name="transfer_doctor_id"
                      disabled={values["transfer_hospital_id"] == ""}
                      error={Boolean(touched["user_id"] && errors["user_id"])}
                      fullWidth
                      onChange={handleChange}
                      options={filteredDoctors ?? []}
                      helperText={touched["user_id"] && errors["user_id"]}
                      onBlur={handleBlur}
                      // value={values}
                      value={values["transfer_doctor_id"]}
                      multiselect={false}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Reason for transfer"
                    name="reason_transfer"
                    value={values["reason_transfer"]}
                    handleChange={handleChange}
                    InputLabelProps={{
                      sx: {
                        // fontFamily: "Montserrat",
                        fontSize: "13px",
                        fontWeight: "400",
                        paddingTop: "2px",
                        lineHeight: "28p",
                      },
                    }}
                    inputProps={{
                      sx: {
                        marginY: "2.5px",
                        fontSize: "13px",
                      },
                      fontSize: "13px",
                      fontWeight: "400",
                      // paddingTop: "2px",
                      // lineHeight: "28p",
                    }}
                    onChange={(e) => {
                      setFieldValue("reason_transfer", e.target.value);
                    }}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={error}
                    multiline={true}
                    rows={1}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction={"column"}>
                    <MgpDD
                      label="Patient's verbal consent obtained?"
                      variant="outlined"
                      size="small"
                      name="verbal_consent"
                      fullWidth
                      onChange={handleChange}
                      options={[
                        { label: "Yes", value: "true" },
                        { label: "No", value: "false" },
                      ]}
                      onBlur={handleBlur}
                      // value={values}
                      value={values["verbal_consent"]}
                      multiselect={false}
                    />
                  </Stack>
                </Grid>
                <Grid item md={2} sm={1}></Grid>
                <Grid item md={8} sm={3}>
                  <Uploader
                    accept=".pdf,.jpg,.jpeg,.png,.gif"
                    ref={uploaderRef}
                    draggable
                    // disabled={values["file_path"] != ""}
                    // listType='text'
                    multiple={false}
                    // onChange={handleUpload}
                    action={`${ENV.file_upload_url}`}
                    onSuccess={(response, file, event, xhr) => {
                      setFieldValue("file_path", response.object_name);
                    }}
                    onRemove={() => {
                      setFieldValue("file_path", "");
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 55,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `2px dotted ${colors.DEVIDER}`,
                        color: `${colors.PRIMARY}`,
                        fontSize: 12,
                        backgroundColor: colors.MODAL_BACKGROUND,
                      }}
                    >
                      <span>
                        Click or Drag files to upload signed consent form
                      </span>
                    </div>
                  </Uploader>
                  {values["file_path"] && (
                    <div>
                      <p>
                        Uploaded Signed Consent Form:{" "}
                        {values["file_path"].split("/")[1]}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid item md={2} sm={1}></Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    paddingTop={1}
                  >
                    <Grid item>
                      <MgpButton
                        variant="contained"
                        onClick={() => {
                          resetForm(initialValues);
                          if (
                            uploaderRef.current &&
                            uploaderRef.current.handleRemoveFile
                          ) {
                            const uploadedFiles =
                              uploaderRef.current.state.fileList || [];
                            uploadedFiles.forEach((file) => {
                              uploaderRef.current.handleRemoveFile(
                                file.fileKey
                              );
                            });
                          }
                          closed();
                        }}
                      >
                        Cancel
                      </MgpButton>
                    </Grid>
                    <Grid item>
                      <MgpButton
                        disabled={
                          values.patient_name == "" ||
                          values.current_doctor == "" ||
                          values.current_hospital == "" ||
                          values.enrollment_date == "" ||
                          values.transfer_hospital_id == "" ||
                          values.national_id == "" ||
                          values.transfer_doctor_id == "" ||
                          values.reason_transfer == "" ||
                          values.verbal_consent === "" ||
                          values.consent_date === ""
                            ? true
                            : values.verbal_consent == "false" &&
                              values.file_path === ""
                            ? true
                            : false
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmitData(values)}
                      >
                        Submit
                      </MgpButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <MgpAlertBar
          open={alertOption}
          setOpen={setAlertOption}
          alertType="success"
          message={alertMessage}
        />
        {/* For Showing patient transfer request is already pending */}
        <MgpAlertBar
          open={alertPending}
          setOpen={setAlertPending}
          alertType="error"
          message={alertMessage}
        />
        {loadingSubmit && (
          <Backdrop open={loadingSubmit} style={{ zIndex: 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </>
  );
};
