
import ExcelJS from "exceljs";


export const downloadExcel = (dataToExport, filename) =>{


    const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");
      const allKeys = [];
      // Collect all keys in the order they first appear
      dataToExport.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (!allKeys.includes(key)) {
            allKeys.push(key);
          }
        });
      });
      const headerRow = worksheet.addRow(allKeys);
      headerRow.font = { bold: true, size: 12 };

//Add data contents to worksheet
      dataToExport.forEach((row) => {
        const rowData = allKeys.map((key) => {
          if (Array.isArray(row[key])) {
            // Handle arrays by creating a new column for each value
            return row[key]
              .map((value) => (value === undefined ? "" : value))
              .join(", ");
          } else {
            return row[key] === undefined ? "" : row[key];
          }
        });
        worksheet.addRow(rowData);
      });
      // Center align all cells
      worksheet.eachRow((row) => {
        row.alignment = { horizontal: "center", vertical: "center" };
      });

      // Adjust column widths
      worksheet.columns.forEach((column) => {
        const maxLength = column.values.reduce((max, value) => {
          return Math.max(max, value ? value.toString().length : 0);
        }, 0);
        column.width = maxLength + 2; // Add some padding
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
      });
}