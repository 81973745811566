import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  FormControlLabel,
  Paper,
  Stack,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks";
import { useNavigate } from "react-router-dom";
import {
  MgpButton,
  MgpCheckbox,
  MgpText,
  MgpTextboxFormik,
  MgpModal,
  MgpRadioButton,
  MgpDD,
} from "../../components/global";
import { colors } from "../../theme/colors";
import { ENV, StorageHelper } from "../../helpers";
import { ForgotPassword } from "../ForgotPassword";
import Loader from "../Loader/Loader";
import Cookies from "js-cookie";
import MgpAlertBar from "../../components/global/MgpAlertBar";
import { width } from "@mui/system";

export const Login = () => {
  const formikRef = React.useRef(null);
  const { useLogin, useUserImpersonate } = useAuth();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [mainloading, setMainLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [chooseLoginType, setLoginType] = useState(false);
  const [Login] = useLogin;
  const [UserImpersonate] = useUserImpersonate;
  const navigate = useNavigate();
  const [initialValue, setInitialValues] = useState({
    username: "",
    password: "",
  });
  const [primaryInvestigatorArray, setPrimaryInvestigatorArray] = useState([]);
  const [sign_in_button_loading, setSignInButtonLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isFalsy = (value) => {
    if (
      value === undefined ||
      value === null ||
      value === "" ||
      Number.isNaN(value)
    ) {
      return true;
    }
    const lowerValue = String(value).trim().toLowerCase();
    return ["undefined", "null", "nan", ""].includes(lowerValue);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    try {
      const savedUser = Cookies.get("userMgpr");
      const remember = Cookies.get("userMgprRememberMe");
      if (savedUser && JSON.parse(remember).rememberMe) {
        setInitialValues({
          ...initialValue,
          username: JSON.parse(savedUser).user.username,
        });
      }
      let token = StorageHelper.get("token");
      let defaultPage = StorageHelper.get("default_page");
      console.log("remember", remember);
      if (isFalsy(token) || !JSON.parse(remember).rememberMe) {
        console.error("Invalid token found. Redirecting to login.");
        setLoading(false);
        navigate("/login");
        return;
      }

      if (!isFalsy(defaultPage)) {
        const sanitizedPage = defaultPage.replace(/"/g, "");
        console.log("Navigating to default page:", sanitizedPage);
        setLoading(false);
        navigate(sanitizedPage);
      } else {
        console.warn("Invalid or missing default page. Redirecting to login.");
        setLoading(false);
        navigate("/login");
      }
    } catch (error) {
      console.error(
        "An error occurred while processing the token/defaultPage:",
        error
      );
      navigate("/login");
    }
  }, [navigate]);
  const emailRef = useRef(null); // Create a ref for the email input
  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus(); // Set focus to the email input field when the component mounts
    }
  }, []);
  console.log("70", initialValue);

  const [loginRoleList, setLoginRole] = useState([
    { value: "1", label: "" },
    { value: "2", label: "Sub Investigator" },
  ]);
  const [primaryInvestigator, setPrimaryInvestigator] = useState("");
  const [loginTypeValue, setloginTypeValue] = useState("");

  const [loginResponse, setLoginResponse] = useState({});
  const [userImpersonateToken, setUserImpersonateToken] = useState("");
  const [userImpersonateUsername, setUserImpersonateUsername] = useState("");
  const [message, setMessage] = useState("");
  const [alertOption, setAlertOption] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [mainUserLoggedInRoleId, setMainUserLoggedInRoleId] = useState("");

  const handleSubmitClose = () => {
    setAlertOption(false);
  };

  const selectLoginType = (item) => {
    setloginTypeValue(item);
  };

  const handleOnUserImpersonate = () => {
    if (loginTypeValue == 1) {
      UserLogin(loginResponse);
    } else if (loginTypeValue == 2) {
      if (!userImpersonateUsername) {
        setAlertOption(true);
        setAlertType("error");
        setMessage("Please select primary investigator to login");
      } else {
        UserImpersonateLogin({
          token: userImpersonateToken,
          username: userImpersonateUsername,
        });
      }
    } else {
      if (mainUserLoggedInRoleId == ENV.secondary_investigator_role_id) {
        if (!userImpersonateUsername) {
          setAlertOption(true);
          setAlertType("error");
          setMessage("Please select primary investigator to login");
        } else {
          UserImpersonateLogin({
            token: userImpersonateToken,
            username: userImpersonateUsername,
          });
        }
      } else {
        setAlertOption(true);
        setAlertType("error");
        setMessage("Please select the role to login");
      }
    }
  };

  const UserLogin = (LoginResponse) => {
    if (rememberMe) {
      const expiration = rememberMe ? { expires: 7 } : undefined; // 7 days for remember me
      Cookies.set(
        "userMgpr",
        JSON.stringify({
          token: LoginResponse?.data?.Login?.access_token,
          user: LoginResponse?.data?.Login?.user,
          default_page: LoginResponse?.data?.Login?.default_page,
        }),
        expiration
      );
    }
    Cookies.set(
      "userMgprRememberMe",
      JSON.stringify({
        rememberMe: rememberMe,
      }),
      { expires: 100 }
    );
    StorageHelper.set("token", LoginResponse?.data?.Login?.access_token);
    StorageHelper.set("user", JSON.stringify(LoginResponse?.data?.Login?.user));
    StorageHelper.set(
      "default_page",
      JSON.stringify(LoginResponse?.data?.Login?.default_page)
    );
    StorageHelper.set(
      "assigned_hospitals",
      JSON.stringify(LoginResponse?.data?.Login?.assigned_hospitals)
    );
    setLoading(false);
    setRememberMe(false);
    navigate(LoginResponse?.data?.Login?.default_page);
  };

  const UserImpersonateLogin = async ({ token, username }) => {
    setSignInButtonLoading(true);
    try {
      const UserImpersonateResponse = await UserImpersonate({
        variables: {
          token,
          username,
        },
      });
      StorageHelper.set(
        "subinvestigator_id",
        UserImpersonateResponse?.data?.UserImpersonate?.sub_investigator
          ?.user_id
      );
      StorageHelper.set(
        "token",
        UserImpersonateResponse?.data?.UserImpersonate?.access_token
      );
      StorageHelper.set(
        "user",
        JSON.stringify(UserImpersonateResponse?.data?.UserImpersonate?.user)
      );
      StorageHelper.set(
        "default_page",
        JSON.stringify(
          UserImpersonateResponse?.data?.UserImpersonate?.default_page
        )
      );
      StorageHelper.set(
        "assigned_hospitals",
        JSON.stringify(
          UserImpersonateResponse?.data?.UserImpersonate?.assigned_hospitals
        )
      );
      StorageHelper.set(
        "sub_investigator",
        JSON.stringify(
          UserImpersonateResponse?.data?.UserImpersonate?.sub_investigator
        )
      );
      setSignInButtonLoading(false);
      setRememberMe(false);
      navigate(UserImpersonateResponse?.data?.UserImpersonate?.default_page);
    } catch (error) {
      console.log("errorrr", error.message);
      setSignInButtonLoading(false);
      if (error.message === "Invalid credentials") {
        setAlertOption(true);
        setAlertType("error");
        setMessage(
          "Authentication failed! Please check your password and try again."
        );
      } else if (error.message === "User does not exist") {
        setAlertOption(true);
        setAlertType("error");
        setMessage(
          "Username does not exist! Please check your username and try again."
        );
      } else if (error.message === "User is not active") {
        setAlertOption(true);
        setAlertType("error");
        setMessage("User is not active! Please check with ITKAN.");
      } else {
        setAlertOption(true);
        setAlertType("error");
        setMessage("Something went wrong");
      }
    }
  };
  return (
    <Grid container>
      <Grid item xs={6}>
        <Stack>
          <img
            src="MGPR Landing Page BG.png"
            alt=""
            style={{
              width: "100%",
              height: "100vh",
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack
          height="100vh"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          overflow="auto"
          paddingX={{ xs: 2, md: 5 }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            paddingX={{ xs: 2, md: 5 }}
          >
            <Grid item>
              <Stack direction="column" alignItems="center" spacing={2}>
                <img src="logo.png" alt="" style={{ width: "170px" }} />
                <MgpText
                  sx={{ color: colors.PRIMARY_TEXT }}
                  variant="h3"
                  display="block"
                >
                  National Myasthenia Gravis Registry{" "}
                </MgpText>
              </Stack>
            </Grid>
            <Grid item>
              <Stack
                flexGrow={1}
                display="flex"
                flexDirection="column"
                width="100%"
                border={`1px solid ${colors.WHITE}`}
                borderRadius="20px"
                // boxShadow={`4px 2px 4px 4px rgba(0, 0, 0, 0.1)`}
                padding="20px"
              >
                <Typography
                  variant="caption"
                  display="block"
                  sx={{ color: colors.SECONDARY_TEXT }}
                  gutterBottom
                >
                  Hi, Welcome Back! Login to your account
                </Typography>

                <Formik
                  innerRef={formikRef}
                  initialValues={initialValue}
                  validationSchema={Yup.object().shape({
                    username: Yup.string().max(255).required("Username is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                  enableReinitialize={true}
                  onSubmit={async (payload, { setErrors }) => {
                    setLoading(true);
                    try {
                      const LoginResponse = await Login({
                        variables: {
                          username: payload.username.toLowerCase().trim(),
                          password: payload.password,
                        },
                      });
                      setLoginResponse(LoginResponse);
                      StorageHelper.remove("subinvestigator_id");
                      if (
                        LoginResponse?.data?.Login?.principal_investigators
                          .length > 0
                      ) {
                        setUserImpersonateToken(
                          LoginResponse?.data?.Login?.access_token
                        );
                        const updatedLoginRoleList = loginRoleList.map(
                          (item) => {
                            if (item.value == 1) {
                              return {
                                ...item,
                                label:
                                  LoginResponse?.data?.Login?.user?.role_name,
                              };
                            } else {
                              return { ...item };
                            }
                          }
                        );
                        setLoginRole(updatedLoginRoleList);
                        const primary_investigators =
                          LoginResponse?.data?.Login?.principal_investigators.map(
                            (item) => {
                              return {
                                label: item?.first_name + " " + item?.last_name,
                                value: item?.username,
                              };
                            }
                          ) || [];
                        setPrimaryInvestigatorArray(primary_investigators);
                        if (primary_investigators.length === 1) {
                          setPrimaryInvestigator(
                            primary_investigators[0]?.value
                          );
                          setUserImpersonateUsername(
                            primary_investigators[0]?.value
                          );
                        }

                        // for secondary investigator role
                        if (
                          parseInt(LoginResponse?.data?.Login?.user?.role_id) ==
                          parseInt(ENV.secondary_investigator_role_id)
                        ) {
                          // for single principal investigator
                          if (
                            LoginResponse?.data?.Login?.principal_investigators
                              .length == 1
                          ) {
                            // UserImpersonateLogin({
                            //   token: LoginResponse?.data?.Login?.access_token,
                            //   email:
                            //     LoginResponse?.data?.Login
                            //       ?.principal_investigators[0]?.email,
                            // });
                            setMainUserLoggedInRoleId(
                              LoginResponse?.data?.Login?.user?.role_id
                            );
                            setLoginType(true);
                            setLoading(false);
                          }
                          // for multiple principal investigators
                          else {
                            setMainUserLoggedInRoleId(
                              LoginResponse?.data?.Login?.user?.role_id
                            );
                            setLoginType(true);
                            setLoading(false);
                          }
                        }
                        // for other roles other than secondary investigator
                        else {
                          setMainUserLoggedInRoleId(
                            LoginResponse?.data?.Login?.user?.role_id
                          );
                          setLoginType(true);
                          setLoading(false);
                        }
                      } else {
                        UserLogin(LoginResponse);
                      }
                    } catch (error) {
                      console.log("errorrr", error.message);
                      setLoading(false);
                      if (error.message === "Invalid credentials") {
                        setErrors({
                          general:
                            "Authentication failed! Please check your password and try again.",
                        });
                      } else if (error.message === "User does not exist") {
                        setErrors({
                          general:
                            "User does not exist! Please check your username and try again.",
                        });
                      } else if (error.message === "User is not active") {
                        setAlertOption(true);
                        setAlertType("error");
                        setMessage(
                          "User is not active! Please check with ITKAN."
                        );
                      } else {
                        setErrors({
                          general: "Something went wrong",
                        });
                      }
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    values,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      style={{ width: "100%" }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <MgpText
                            color="error"
                            textAlign="center"
                            fontSize={12}
                          >
                            {errors.general}
                          </MgpText>
                        </Grid>
                        <Grid item xs={12}>
                          <MgpTextboxFormik
                            variant="outlined"
                            fullWidth
                            size="small"
                            label="Username"
                            name="username"
                            splits={"username".split(".")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            touched={touched}
                            inputRef={emailRef}
                            // errors={errors}
                            // helperText={touched.email && errors.email}
                            error={touched["username"] && Boolean(errors["username"])}
                            helperText={touched["username"] && errors["username"]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MgpTextboxFormik
                            fullWidth
                            size="small"
                            label="Password"
                            name="password"
                            splits={"password".split(".")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            variant="outlined"
                            showPassword={showPassword}
                            type={showPassword ? "text" : "password"}
                            error={
                              touched["password"] && Boolean(errors["password"])
                            }
                            helperText={
                              touched["password"] && errors["password"]
                            }
                            handleClickShowPassword={handleClickShowPassword}
                            handleMouseDownPassword={handleMouseDownPassword}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        paddingTop="1rem"
                      >
                        <Grid item xs={8}>
                          {/* <Typography
                            variant="caption"
                            sx={{
                              color: colors.SECONDARY_TEXT,
                              fontSize: "12px",
                            }}
                          > */}
                          {/* <FormControlLabel
                              control={
                                <MgpCheckbox
                                  defaultChecked
                                  size="small"
                                  color="primary"
                                />
                              }
                              label="I agree to terms & conditions"
                              variant="caption"
                              display="block"
                              sx={{
                                color: colors.SECONDARY_TEXT,
                                fontSize: "12px",
                              }}
                            /> */}

                          {/* <FormControlLabel
                              control={
                                <MgpCheckbox
                                  size="small"
                                  color="primary"
                                  checked={rememberMe}
                                  onChange={(e) => { setRememberMe(e.target.checked) }}
                                />
                              }
                              label="Remember Me"
                              variant="caption"
                              display="block"
                              sx={{
                                color: colors.SECONDARY_TEXT,
                                fontSize: "12px",
                                '& .MuiFormControlLabel-label': {
                                  fontSize: "12px", // Ensure this targets the label specifically
                                },
                              }}
                            />

                          </Typography> */}
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <MgpText
                            sx={{
                              cursor: "pointer",
                              color: colors.SECONDARY_TEXT,
                            }}
                            variant="caption"
                            display="block"
                            onClick={handleOpen}
                          >
                            Forgot password?
                          </MgpText>
                        </Grid>
                      </Grid>
                      {/* <FormControlLabel
                      control={<MgpCheckbox defaultChecked size="small" color="primary" />}
                      label="I agree to terms & conditions"
                      variant="caption" display="block"
                      sx={{ color: colors.SECONDARY_TEXT }}
                    /> */}
                      <MgpButton
                        onClick={handleSubmit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        style={{ marginTop: "1rem" }}
                      >
                        SIGN IN
                        {loading && (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: "20px" }}
                          />
                        )}
                      </MgpButton>
                      <Loader open={mainloading} />
                    </form>
                  )}
                </Formik>
              </Stack>
            </Grid>
          </Grid>

          <div
            style={{
              // border:"2px solid yellow",
              backgroundImage: colors.FOOTER,
              borderRadius: "5px",
              position: "fixed",
              bottom: 16,
              right: 16,
              padding: "10px 20px",
              // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/Itkan-W_edited.webp"}
              alt=""
              style={{ width: "90px" }}
            />
          </div>
        </Stack>
        <MgpModal
          styling={{ width: "40vw", overflow: "auto" }}
          open={open}
          handleOpen={handleOpen}
          onClose={handleClose}
        >
          <ForgotPassword handleClose={handleClose}></ForgotPassword>
        </MgpModal>

        {/* Secondary investigator who has only one primary investigator */}
        <MgpModal
          title={
            mainUserLoggedInRoleId == ENV.secondary_investigator_role_id
              ? "Choose Primary Investigator"
              : "Choose Role"
          }
          open={chooseLoginType}
          onClose={() => {
            setLoginType(false);
          }}
          styling={{width:"40vw"}}
        >
          <Grid container spacing={1}>
            {mainUserLoggedInRoleId != ENV.secondary_investigator_role_id && (
              <Grid item xs={12}>
                <Stack
                    justifyContent={"center"}
                    direction={"row"}
                  >
                <MgpRadioButton
                  value={loginTypeValue}
                  onChange={selectLoginType}
                  list={loginRoleList}
                ></MgpRadioButton>
                </Stack>
              </Grid>
            )}
            {mainUserLoggedInRoleId != ENV.secondary_investigator_role_id &&
              loginTypeValue == 2 &&
              primaryInvestigatorArray.length > 0 && (
                <Grid item md={12}>
                  <MgpDD
                    label="Select Principal Investigator"
                    variant="outlined"
                    size="small"
                    name="primary_investigator"
                    multiselect={false}
                    fullWidth
                    value={primaryInvestigator}
                    options={primaryInvestigatorArray}
                    onChange={(e) => {
                      setPrimaryInvestigator(e.target.value);
                      setUserImpersonateUsername(e.target.value);
                    }}
                    // onBlur={handleBlur}
                    // error={Boolean(touched.primary_investigator && errors.primary_investigator)}
                    // helperText={touched.primary_investigator && errors.primary_investigator}
                  ></MgpDD>
                </Grid>
              )}
            {mainUserLoggedInRoleId == ENV.secondary_investigator_role_id &&
              primaryInvestigatorArray.length > 0 && (
                <Grid item xs={12}>
                  <MgpDD
                    label="Select Principal Investigator(s)"
                    variant="outlined"
                    size="small"
                    name="primary_investigator"
                    multiselect={false}
                    fullWidth
                    value={primaryInvestigator}
                    options={primaryInvestigatorArray}
                    onChange={(e) => {
                      setPrimaryInvestigator(e.target.value);
                      setUserImpersonateUsername(e.target.value);
                    }}
                    // onBlur={handleBlur}
                    // error={Boolean(touched.primary_investigator && errors.primary_investigator)}
                    // helperText={touched.primary_investigator && errors.primary_investigator}
                  ></MgpDD>
                </Grid>
              )}
              <Grid item md={4}></Grid>
            <Grid item md={4}>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    direction={"row"}
                  >
              <MgpButton
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setSignInButtonLoading(true);
                  }
                }}
                onClick={() => {
                  handleOnUserImpersonate();
                }}
                fullWidth
                variant="contained"
                color="primary"
                disabled={sign_in_button_loading}
                style={{ marginTop: "1rem" }}
              >
                SIGN IN
                {sign_in_button_loading && (
                  <CircularProgress size={20} style={{ marginLeft: "20px" }} />
                )}
              </MgpButton>
              </Stack>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
        </MgpModal>
        <MgpAlertBar
          open={alertOption}
          setOpen={handleSubmitClose}
          alertType={alertType}
          message={message}
        />
        {/* <MgpModal
          title="Choose Primary Investigators"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        ></MgpModal> */}
      </Grid>
    </Grid>
  );
};
