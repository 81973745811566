import { MgpTextbox } from "./MgpTextbox";
import {
  TextField,
  InputAdornment,
  IconButton,
  Accordion,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UseUtilContext, useRbac, useUtilContext } from "../../hooks";
import { useRef } from "react";
import React, { createContext, useEffect, useState, useContext } from "react";
import { StorageHelper } from "../../helpers";
import { CustomInputLabel } from "./MgpToggleButtonGroup";
import { colors } from "../../theme/colors";
import { RedAsterisk } from "./MgpAccordion";
import { styled } from "@mui/material/styles";
import { useDebounce } from "use-debounce";

export const MgpTextboxCrf = styled((props) => (
  <TextField
    {...props}
    autoComplete="mgp-new-password"
    inputProps={{
      ...props.inputProps,
      autoComplete: "off",
    }}
  />
))(({ theme }) => ({
  backgroundColor: "white", // Set the background color for the TextField
  borderRadius: "8px", // Set corner radius
  height: "40px", // Set height for TextField

  "& label": {
    "&.with-asterisk::after": {
      content: '"*"',
      color: "red", // Asterisk color
      fontSize: "18px",
    },
  },

  "& .MuiInputBase-input": {
    borderColor: "white",
    borderRadius: "5px", // Set border radius for input
    fontFamily: "'Poppins', sans-serif", // Set font family
    fontSize: "13px", // Set font size
    fontWeight: "400", // Set font weight
    backgroundColor: "white", // Background color for the input
    height: "40px", // Set height for input
    padding: "0 10px", // Add padding for input text
  },

  "& .MuiInputBase-root": {
    borderColor: "white !important",
    backgroundColor: "white !important", // Background for input base
    height: "40px", // Set height for input base
    borderRadius: "5px", // Maintain border radius for input base
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white !important", // Change border color to white
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "white !important", // Keep the border color white on hover
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "white !important", // Keep the border color white when focused
  },

  // Disabled state styles
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(255, 21, 21) !important", // Set border color to red when disabled
  },

  "&.Mui-disabled .MuiInputBase-root": {
    backgroundColor: "white !important", // Set background color to white in disabled state
    borderRadius: "5px",
  },

  "&.Mui-disabled .MuiInputBase-input": {
    backgroundColor: "white", // Ensure background remains white
  },

  // Hover effect for enabled state
  "&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#b35c5c", // Change border color on hover when not disabled
  },

  "&:focus .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Ensure border remains white on focus
  },

  "&:active .MuiOutlinedInput-notchedOutline": {
    borderColor: "white", // Ensure border remains white on active
  },
}));
export const CrfTextField = ({
  disabled,
  splits,
  name,
  label,
  error,
  handleBlur,
  setFieldValue,
  values,
  attr,
  size,
  limit,
  viewMode,
  allowed,
  value,
  helperText,
  handleChange,
  dependent,
  comment,
  setCommentMessage,
  commentMessage,
  transferPatient,
  handleChangeNationalId,
  type,
  from,
  aggrigation,
  dependentaggrigation,
  numberformat,
  subtracting,
  reversedependent,
  dependentyear,
  commentData,
  encountedDataLatestStatus,
  dependent1,
  dependent2,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  dependentMain,
  edit,
  parentName,
  parentType,
  float,
  edit_patientform,
  enableEnter,
  textBoxRefs,
  inputRef,
  handleFocus,
  setlimit,
  autoFocus,
  startingnumber,
  setFieldTouched,
  attribute,
  checkstartdate,
}) => {
  let fieldvalue = values?.[name];

  const textFieldRef = useRef(null);
  const [event, setEvent] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");

  const [debouncedValue] = useDebounce(inputValue, 500);
  useEffect(() => {
    if (autoFocus && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (handleChange) {
      handleChange(name, attribute);
      if (handleFocus) {
        handleFocus(event);
      }
    }
  }, [debouncedValue]);

  const onBlur = (event) => {
    // if (handleBlur) {
    //   handleBlur(event);
    // }
  };
  const numberPattern = "/^d*$/";
  const calculateBirthDate = (age) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear() - age;
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const birthDate = new Date(year, month, day);
    return birthDate.toISOString().split("T")[0]; // Return in 'YYYY-MM-DD' format
  };
  const handleKeyPress = (currentFieldId, nextFieldId, event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter key
      if (textBoxRefs.current[nextFieldId]) {
        textBoxRefs.current[nextFieldId].current.focus();
      }
    }
  };
  function monthsDiff(date1, date2) {
    let startDate = new Date(date1);
    let endDate = new Date(date2);
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }
    const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthsDiff = endDate.getMonth() - startDate.getMonth();

    const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

    // if(totalMonthsDiff>=0 && totalMonthsDiff<=3)
    // {
    //   return '0-3 Months'
    // }
    // else if(totalMonthsDiff>3 && totalMonthsDiff<=6)
    // {
    //   return '3-6 Months'
    // }
    // else if(totalMonthsDiff>6 && totalMonthsDiff<=12)
    // {
    //   return '6-12 Months'
    // }
    // else
    // {
    //   return 'More than 12 Months';
    // }
    return !isNaN(totalMonthsDiff) ? `${totalMonthsDiff} Months` : "";
  }
  function monthsDiffYM(date1, date2) {
    let startDate = new Date(date1);
    let endDate = new Date(date2);

    // Swap dates if startDate is later than endDate
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }

    // Calculate the difference in years and months
    let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthsDiff = endDate.getMonth() - startDate.getMonth();

    // If the months difference is negative, adjust the years and months difference
    if (monthsDiff < 0) {
      yearsDiff -= 1;
      monthsDiff += 12;
    }

    // Return the result as an object
    return {
      years: !isNaN(yearsDiff) ? `${yearsDiff} Years` : "",
      months: !isNaN(monthsDiff) ? `${monthsDiff} Months` : "",
    };
  }
  const labelText = label;
  const hasAsterisk = labelText?.endsWith("*");

  return (
    <div>
      <CustomInputLabel
        sx={{
          marginBottom: "4px",
          color: error ? colors.ERROR : colors.CFRBLUE,
          fontFamily: "'Poppins', sans-serif",
          fontSize: "13px",
          fontWeight: "normal",
          whiteSpace: "normal",
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {values?.[name] === undefined || values?.[name] === "" ? (
          hasAsterisk ? (
            <>
              {label?.slice(0, -1)}
              <RedAsterisk>*</RedAsterisk>
            </>
          ) : (
            label
          )
        ) : label === "Total Score" ? (
          "Total Score"
        ) : hasAsterisk ? (
          <>
            {label?.slice(0, -1)}
            <RedAsterisk>*</RedAsterisk>
          </>
        ) : (
          label
        )}
      </CustomInputLabel>
      <form autoComplete="off">
        <MgpTextboxCrf
          fullWidth
          id="outlined-error"
          sx={{ alignSelf: "end" }}
          type={type}
          error={error}
          size="small"
          inputRef={
            inputRef
              ? inputRef
              : enableEnter && textBoxRefs.current[name]
              ? textBoxRefs.current[name]
              : textFieldRef
          }
          // onKeyDownCapture={(event)=>{
          //   console.log("85555",event)
          // }}
          onKeyDownCapture={(event) => {
            if (enableEnter) handleKeyPress(name, enableEnter, event);
          }}
          disabled={
            edit_patientform && !disabled
              ? false
              : (parentType === "Accordion" &&
                  (encountedDataLatestStatus === 4 ||
                    encountedDataLatestStatus === 6)) ||
                encountedDataLatestStatus === 4 ||
                encountedDataLatestStatus === 6
              ? !edit
                ? true
                : disabled
              : disabled || viewMode
              ? true
              : false
          }
          helperText={helperText}
          name={name}
          value={
            name === "PPCI_mgpf"
              ? values?.["PPCI_firstname"]
              : name === "PPCI_mgpl"
              ? values?.["PPCI_lastname"]
              : name === "PPCI_mgpe"
              ? values?.["PPCI_email"]
              : values?.[name]
          }
          typeof={Number}
          onKeyDown={(event) => {
            if (allowed !== undefined) {
              // console.log("allowed", allowed);

              const allowedKeys = allowed;

              // Limit the length to 1 character
              if (
                value.length >= 1 &&
                event.key !== "Backspace" &&
                event.key !== "Delete"
              ) {
                event.preventDefault();
                return;
              }

              // Allow only allowed keys and handle "Delete" key
              if (
                !allowedKeys.includes(event.key) &&
                event.key !== "Backspace"
              ) {
                event.preventDefault();
              }

              // Handle "Delete" key to clear the TextField value
              if (event.key === "Delete") {
                setFieldValue(name, "");
              }
            }
          }}
          inputProps={{
            sx: {
              fontSize: "16px",
              autoComplete: "new-password",
            },
            maxLength: limit !== undefined ? limit : 100,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontWeight: "400",
            // paddingTop: "2px",
            // lineHeight: "28p",
          }}
          onChange={(e, val) => {
            setInputValue(e.target.value);
            setEvent(e);
            if (name === "PPCI_mgpf") {
              setFieldValue("PPCI_firstname", e.target.value);
              setFieldValue(name, e.target.value);
              return;
            }
            if (name === "PPCI_mgpl") {
              setFieldValue("PPCI_lastname", e.target.value);
              setFieldValue(name, e.target.value);
              return;
            }
            if (name === "PPCI_mgpe") {
              setFieldValue("PPCI_email", e.target.value);
              setFieldValue(name, e.target.value);
              return;
            }
            const newValue = e.target.value;
            const regex = /^[\x20-\x7E]*$/;

            if (regex.test(e.target.value)) {
              // console.log("newValue", newValue);
              if (setlimit) {
                const limitCheck = Number(newValue);
                if (
                  !isNaN(limitCheck) &&
                  limitCheck <= setlimit &&
                  newValue !== "0"
                ) {
                  setFieldValue(name, newValue);
                } else {
                  setFieldValue(name, "");
                }
              } else {
                if (numberformat && float) {
                  if (numberformat) {
                    if (!values?.[name]) {
                      setFieldValue(name, "");
                    }

                    if (newValue.match(/^\d*\.?\d*$/)) {
                      if (subtracting) {
                        setFieldValue(name, newValue);
                        const dateString1 = values?.[dependentyear];
                        // Convert the date string to a Date object
                        const date = new Date(dateString1);

                        // Add 4 years to the date
                        date.setFullYear(date.getFullYear() + Number(newValue));

                        // Convert the updated date back to a string
                        const updatedDateString = date.toISOString();
                        setFieldValue(reversedependent, updatedDateString);
                      } else {
                        setFieldValue(name, newValue);
                      }
                    } else {
                    }
                  } else {
                    setFieldValue(name, newValue);
                  }
                } else {
                  if (startingnumber) {
                    let newValues = e.target.value;

                    newValues = "05" + newValues.slice(2).replace(/\D/g, "");
                    setFieldValue(name, newValues);
                  } else {
                    if (numberformat) {
                      if (!values?.[name]) {
                        setFieldValue(name, "");
                      }
                      if (newValue.match(/^\d*$/)) {
                        if (subtracting) {
                          let updatedDateString;
                          if (checkstartdate) {
                            const dateString1 = values?.[dependentyear];
                            const datestartfrom1 = values?.[checkstartdate];

                            if (dateString1 && datestartfrom1) {
                              const startDate = new Date(dateString1);
                              const datestartfrom = new Date(datestartfrom1);
                              const date = new Date(startDate);
                              date.setFullYear(
                                date.getFullYear() + Number(newValue)
                              );

                              const maxDate = new Date();

                              setFieldValue(name, newValue);

                              if (date >= datestartfrom && date <= maxDate) {
                                setFieldValue(name, newValue);
                                updatedDateString = date.toISOString();
                                setFieldValue(
                                  reversedependent,
                                  updatedDateString
                                );
                              } else {
                                setFieldValue(reversedependent, "");
                              }
                            } else {
                              setFieldValue(reversedependent, "");
                            }
                          } else {
                            const dateString1 = values?.[dependentyear];
                            const startDate = new Date(dateString1);
                            const date = new Date(startDate);
                            updatedDateString = date.toISOString();
                            date.setFullYear(
                              date.getFullYear() + Number(newValue)
                            );
                            const maxDate = new Date(); //Today's date

                            if (date >= startDate && date <= maxDate) {
                              setFieldValue(name, newValue);
                              updatedDateString = date.toISOString();
                              setFieldValue(
                                reversedependent,
                                updatedDateString
                              );
                            } else {
                              setFieldValue(name, "");
                              setFieldValue(reversedependent, "");
                            }
                          }
                          if (aggrigation === "5") {
                            if (Array.isArray(dependentMain)) {
                              let valueCheck = dependentMain.map((elem) => {
                                if (
                                  values?.[elem] !== "" ||
                                  values?.[elem] !== null
                                )
                                  return true;
                              });

                              if (!valueCheck.includes(false)) {
                                let first =
                                  reversedependent === dependentMain[1]
                                    ? updatedDateString
                                    : values?.[dependentMain[1]];
                                let second =
                                  reversedependent === dependentMain[0]
                                    ? updatedDateString
                                    : values?.[dependentMain[0]];
                                let diff = monthsDiff(first, second);

                                // setFieldValue("1993");
                                let diffYM = monthsDiffYM(first, second);

                                setFieldValue(dependent1, diff);
                                setFieldValue(`${dependent1}-Y`, diffYM?.years);
                                setFieldValue(
                                  `${dependent1}-M`,
                                  diffYM?.months
                                );
                              }
                            }
                          }
                        } else {
                          setFieldValue(name, newValue);
                        }
                      } else {
                      }
                    } else {
                      setFieldValue(name, newValue);
                    }
                  }
                }
              }

              if (aggrigation === "1") {
                if (
                  values?.[dependentaggrigation] === undefined ||
                  values?.[dependentaggrigation] === ""
                ) {
                  setFieldValue(dependentaggrigation, parseInt(newValue));
                } else {
                  if (newValue !== "") {
                    let sum = +parseInt(values?.[dependentaggrigation]);
                    if (fieldvalue) {
                      sum -= parseInt(fieldvalue);
                    }
                    sum += parseInt(newValue);
                    setFieldValue(dependentaggrigation, sum);
                  } else {
                    let sum = +parseInt(values?.[dependentaggrigation]);
                    sum -= parseInt(fieldvalue);
                    setFieldValue(dependentaggrigation, sum.toString());
                  }
                }
              }
              if (aggrigation === "2") {
                if (
                  values?.[dependentaggrigation] === undefined ||
                  values?.[dependentaggrigation] === ""
                ) {
                  setFieldValue(dependentaggrigation, parseInt(newValue));
                } else {
                  if (newValue !== "") {
                    let sum = +parseInt(values?.[dependentaggrigation]);
                    if (fieldvalue) {
                      sum -= parseInt(fieldvalue);
                    }
                    sum += parseInt(newValue);
                    setFieldValue(dependentaggrigation, sum);
                  } else {
                    let sum = +parseInt(values?.[dependentaggrigation]);
                    sum -= parseInt(fieldvalue);
                    setFieldValue(dependentaggrigation, sum.toString());
                  }
                }
              }
              if (aggrigation === "3") {
                if (setlimit) {
                  const limitCheck = Number(newValue);
                  if (!isNaN(limitCheck) && limitCheck <= setlimit) {
                    setFieldValue(name, newValue);
                  } else {
                    setFieldValue(name, 0);
                  }
                } else {
                  if (dependentaggrigation === name) {
                    if (
                      values?.[dependentaggrigation] === undefined ||
                      values?.[dependentaggrigation] === null
                    ) {
                      setFieldValue(dependentaggrigation, "");
                    } else {
                      setFieldValue(name, newValue);
                    }
                  } else {
                    if (
                      values?.[dependentaggrigation] === undefined ||
                      values?.[dependentaggrigation] === null
                    ) {
                      setFieldValue(dependentaggrigation, parseInt(newValue));
                    } else {
                      if (newValue !== "") {
                        if (values?.[dependentaggrigation] === "") {
                          let sum = +parseInt(0);
                          sum += parseInt(newValue);
                          setFieldValue(dependentaggrigation, parseInt(sum));
                        } else {
                          let sum = +parseInt(values?.[dependentaggrigation]);
                          if (fieldvalue) {
                            sum -= parseInt(fieldvalue);
                          }
                          sum += parseInt(newValue);
                          setFieldValue(dependentaggrigation, parseInt(sum));
                        }
                      } else {
                        let sum = +parseInt(values?.[dependentaggrigation]);
                        sum -= parseInt(fieldvalue);
                        setFieldValue(dependentaggrigation, parseInt(sum));
                      }
                    }
                  }
                }
              }
              if (aggrigation === "4") {
                let weight = 0;
                let height = 0;
                if (newValue !== "" && dependent1 !== undefined) {
                  if (name === dependent2) {
                    weight = parseInt(newValue);
                    if (
                      values?.[dependent1] !== undefined ||
                      values?.[name] !== ""
                    ) {
                      height = values?.[dependent1];
                      const heightInMeters = height / 100;
                      const bmiValue =
                        weight / (heightInMeters * heightInMeters) == Infinity
                          ? 0
                          : weight / (heightInMeters * heightInMeters);
                      setFieldValue(
                        dependentaggrigation,
                        parseFloat(bmiValue).toFixed(1)
                      );
                    } else {
                      setFieldValue(dependentaggrigation, "");
                    }
                  } else {
                    if (
                      dependent1 !== undefined &&
                      values?.[dependent1] !== ""
                    ) {
                      height = parseInt(newValue);
                      if (
                        values?.[dependent1] !== undefined ||
                        values?.[dependent1] !== ""
                      ) {
                        weight = parseInt(values?.[dependent1]);
                        const heightInMeters = height / 100;
                        const bmiValue =
                          weight / (heightInMeters * heightInMeters);
                        setFieldValue(
                          dependentaggrigation,
                          parseFloat(bmiValue).toFixed(1)
                        );
                      } else {
                        setFieldValue(dependentaggrigation, "");
                      }
                    }
                  }
                } else {
                  setFieldValue(dependentaggrigation, "");
                }
              }
              if (aggrigation === "6") {
                setFieldValue(name, newValue);
              }
            }
          }}
          onBlur={onBlur}
          variant="outlined"
        />
      </form>
    </div>
  );
};
