import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepButton, StepLabel } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { colors } from "../../theme/colors";
import { postProcessData, preProcessData } from "../../hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { StorageHelper } from "../../helpers";
import { useRef, useCallback } from "react";

export default function MgpProgressBar({
  commentData,
  steps,
  completed,
  activeStep,
  setActiveStep,
  setCompleted,
  setSelectedSection,
  crfErrors,
  crfTouch,
  encountedData,
  formikRef,
  patient_selected_section,
  encountedDataLatestStatus,
  submitErrors,
  params,
  Allerrors,
  setAllerrors,
  forms,
  encounterId,
}) {
  const handleStep = (step, label) => () => {
    if (params?.nationalId || params?.patientId) {
    } else {
      setActiveStep(step);
      setSelectedSection(label);
    }
  };
  setAllerrors(false);

  function areAllFieldsEmpty(
    data,
    excludeFields = [
      "",
      "typeofmgs",
      "cause",
      "countrylist",
      "created",
      "date",
      "death",
      "doctor",
      "firstVisit",
      "hospital",
      "is",
      "typeofvisits",
      "lastVisit",
      "nationality",
      "others",
      "patient",
      "registryno",
      "status",
      "totalVisits",
      "updated",
      "MGseverity",
      "surgerythymectomys",
      "ImmunosuppressiveTreatment",
      "rapidimmunotherapies",
      "ImmunosuppressiveTreatmentCurrentMedication",
    ]
  ) {
    if (typeof data === "string") {
      if (data.trim() === "") return true;

      try {
        const parsed = JSON.parse(data);
        if (
          parsed.hasOwnProperty("cascadervalue") &&
          parsed.hasOwnProperty("value") &&
          Array.isArray(parsed.cascadervalue) &&
          parsed.cascadervalue.length === 0 &&
          Object.keys(parsed.value).length === 0
        ) {
          return true;
        }
      } catch (e) {
        return false;
      }
      return false;
    }

    if (Array.isArray(data)) {
      return data.every((item) => areAllFieldsEmpty(item, excludeFields));
    }

    if (typeof data === "object" && data !== null) {
      return Object.entries(data).every(([key, value]) => {
        if (excludeFields.includes(key)) return true;

        if (key === "isChecked") {
          return (
            value === "" ||
            value === false ||
            value === null ||
            value === undefined
          );
        }

        return areAllFieldsEmpty(value, excludeFields);
      });
    }

    return data === "" || data === null || data === false || data === undefined;
  }

  function checkAllEmpty(obj, fields, name, type, cascadeValidation, options) {
    let cascadevalidate = false;

    const dependentValidationarray1 = [
      "TreatmentIntervention_PreviousNone_isChecked",
      "TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIG_isChecked",
      "TreatmentIntervention_corticosteroidstreatment_isChecked",
      "TreatmentIntervention_acetylcholinesteraseinhibitors_isChecked",
      "TreatmentIntervention_CyclicPlasmapheresisPLEX_isChecked",
      "TreatmentIntervention_BoneMarrowTransplant(BMT)_isChecked",
      "TreatmentIntervention_rapidimmunotherapies_isChecked",
      "TreatmentIntervention_ImmunosuppressiveTreatment_isChecked",
      "TreatmentIntervention_others_isChecked",
    ];
    const dependentValidationarray4 = [
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_frustratedmg",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleeyes",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleeating",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_socialactivity",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_enjoyhobbies",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_needsoffamily",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_makeplans",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_occupationalskills",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_difficultyspeaking",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubledriving",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_depressed",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troublewalking",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troublepublicplaces",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_feeloverwhelmed",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleperformingpersonal",
    ];
    const dependentValidationarray2 = [
      "TreatmentInterventionCurrentMedication_None_isChecked",
      "TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked",
      "TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked",
      "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked",
      "TreatmentIntervention_corticosteroidstreatmentCurrentMedicationothers_isChecked",
    ];
    const dependentValidationarray3 = [
      "Diagnosis_mgseverityclassificationon_unknown_isChecked",
      "Diagnosis_mgseverityclassificationon_MGFA_isChecked",
    ];

    const dependentValidationarray5 = [
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_totalscore",
    ];

    if (obj === undefined || typeof obj !== "object" || obj === null) {
      return false;
    }
    let validKeys;
    let validKeyssecond;
    if (fields === 1) {
      validKeys = dependentValidationarray1.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 2) {
      validKeys = dependentValidationarray2.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 3) {
      validKeys = dependentValidationarray3.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 4) {
      validKeys = dependentValidationarray4.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 4) {
      validKeyssecond = dependentValidationarray5.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 4) {
      const values = validKeys?.map((key) => obj[key]);

      let allNotEmpty =
        Array.isArray(values) &&
        values.every(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === "false" ||
            value === false ||
            value === '{"cascadervalue":[],"value":{}}'
        );

      // Return true if all fields are empty, false if all fields are filled
      let result = allNotEmpty
        ? true
        : values.every(
            (value) => value !== "" && value !== null && value !== undefined
          );

      if (fields === 4) {
        if (allNotEmpty) {
          const valuess = validKeyssecond.map((key) => obj[key]);

          let allEmptys =
            Array.isArray(valuess) &&
            valuess.every(
              (value) => value !== "" && value !== null && value !== undefined
            );

          if (!allEmptys) {
            return true;
          } else {
            return false;
          }
        } else {
          if (!result) {
            return true;
          } else {
            return false;
          }
        }
      }
    } else {
      const values = validKeys?.map((key) => obj?.[key]);
      let allEmpty =
        Array.isArray(values) &&
        values.every(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === "false" ||
            value === false ||
            value === '{"cascadervalue":[],"value":{}}'
        );

      if (allEmpty) {
        return true;
      } else {
        return false;
      }
    }
  }
  function validateField(
    fieldValue,
    format,
    name,
    label,
    error,
    emptyRequiredFields,
    isFieldValid = true
  ) {
    if (!isFieldValid) {
      return;
    }
    if (format === "array") {
      if (!Array.isArray(fieldValue) || fieldValue.length === 0) {
        emptyRequiredFields.push({
          name,
          label: label || name,
          error: `${label || name} is required`,
        });
      }
    } else {
      if (
        fieldValue.toString() === undefined ||
        fieldValue.toString() === null ||
        fieldValue.toString() === ""
      ) {
        emptyRequiredFields.push({
          name,
          label: label || name,
          error: error === "" ? `${label || name} is required` : error,
        });
      }
    }
  }

  function validateRequiredFields(attributes, formData) {
    if (!Array.isArray(attributes)) {
      return { isValid: false, emptyFields: [], error: "Invalid attributes" };
    }

    const emptyRequiredFields = [];

    try {
      for (const attribute of attributes) {
        const {
          name,
          label,
          error,
          required,
          regex,
          format,
          dependentValidation,
          fieldValueValidation,
          dependentValidationarray,
          cascadeValidation,
          cascadevalidation,
          showonlybasline,
          checkVisitType,
          Accordionischecked,
          onlyBasic,
          type,
          dependents,
          fieldValues,
          options,
        } = attribute;
        if (!name) continue;

        const fieldValue = formData[name];

        if (dependentValidationarray) {
          let shouldValidate = false;
          if (
            showonlybasline &&
            formData?.["basicVisit_typeofvisit"] === "Baseline Visit"
          ) {
            shouldValidate = checkAllEmpty(
              formData,
              dependentValidationarray,
              name,
              type,
              cascadeValidation,
              options
            );
          }
          if (showonlybasline === undefined) {
            shouldValidate = checkAllEmpty(
              formData,
              dependentValidationarray,
              name,
              type,
              cascadeValidation,
              options
            );
          }

          if (shouldValidate) {
            if (format === "array") {
              if (!Array.isArray(fieldValue) || fieldValue.length === 0) {
                emptyRequiredFields.push({
                  name,
                  label: label || name,
                  error: error || `${label || name} is required`,
                });
              }
            } else {
              if (!fieldValue || fieldValue === "") {
                emptyRequiredFields.push({
                  name,
                  label: label || name,
                  error: error || `${label || name} is required`,
                });
              }
            }
          }
        } else {
          if (dependentValidation) {
            const dependentValue = formData[dependentValidation];
            if (required) {
              if (Array.isArray(fieldValueValidation)) {
                const shouldValidate = fieldValueValidation.some((elems) =>
                  dependentValue?.includes(elems)
                );

                if (shouldValidate) {
                  validateField(
                    fieldValue,
                    format,
                    name,
                    label,
                    error,
                    emptyRequiredFields
                  );
                }
              } else {
                if (cascadevalidation) {
                  if (
                    formData?.[name] &&
                    type === "cascade" &&
                    cascadeValidation
                  ) {
                    let countValidation = [];
                    options.forEach((elems) => {
                      elems.children.forEach((ele) => {
                        if (ele.label === cascadeValidation) {
                          countValidation.push(`${ele.value} ->`);
                        }
                      });
                    });

                    try {
                      const parsedValue = JSON.parse(formData[name]);
                      const cascadeValidate = countValidation.every((str1) =>
                        parsedValue.cascadervalue.some((str2) =>
                          str2.includes(str1)
                        )
                      );

                      if (
                        formData?.[dependentValidation] === fieldValueValidation
                      ) {
                        if (
                          !cascadeValidate ||
                          formData[name] === '{"cascadervalue":[],"value":{}}'
                        ) {
                          emptyRequiredFields.push({
                            name,
                            label: label || name,
                            error: error || `${label || name} is required`,
                          });
                        }
                      }
                    } catch (e) {
                      console.error(
                        "Invalid JSON format for cascade value:",
                        e
                      );
                    }
                  }
                } else {
                  if (
                    dependentValue?.toString() ===
                    fieldValueValidation?.toString()
                  ) {
                    validateField(
                      fieldValue,
                      format,
                      name,
                      label,
                      error,
                      emptyRequiredFields
                    );
                  }
                }
              }
            } else {
              if (Array.isArray(fieldValueValidation)) {
                if (dependentValue) {
                  const shouldValidate = fieldValueValidation.some((elems) =>
                    dependentValue?.includes(elems)
                  );

                  if (shouldValidate) {
                    validateField(
                      fieldValue,
                      format,
                      name,
                      label,
                      error,
                      emptyRequiredFields
                    );
                  }
                }
              } else {
                if (cascadevalidation) {
                  if (
                    formData?.[dependentValidation] === fieldValueValidation
                  ) {
                    if (
                      fieldValue === "" ||
                      fieldValue === '{"cascadervalue":[],"value":{}}'
                    ) {
                      emptyRequiredFields.push({
                        name,
                        label: label || name,
                        error: error || `${label || name} is required`,
                      });
                    }
                  }
                } else if (checkVisitType) {
                  const visitType = formData[checkVisitType];

                  if (
                    (onlyBasic && visitType === "Baseline Visit") ||
                    (!onlyBasic && visitType === "Follow Up Visit")
                  ) {
                    const isFieldValid =
                      formData[dependentValidation] === fieldValueValidation;
                    if (isFieldValid) {
                      validateField(
                        fieldValue,
                        format,
                        name,
                        label,
                        error,
                        emptyRequiredFields
                      );
                    }
                  }
                } else {
                  const isFieldValid =
                    formData[dependentValidation]?.toString() ===
                    fieldValueValidation?.toString();
                  if (
                    Accordionischecked &&
                    (formData?.[
                      "ParaClinicalInvestigation_serologicaltests_numberoftest"
                    ] === undefined ||
                      formData?.[
                        "ParaClinicalInvestigation_serologicaltests_numberoftest"
                      ] === "")
                  ) {
                    continue;
                  }

                  if (isFieldValid) {
                    validateField(
                      fieldValue,
                      format,
                      name,
                      label,
                      error,
                      emptyRequiredFields
                    );
                  }
                }
              }
            }
          } else {
            if (dependents) {
              const dependentValue = parseInt(formData[dependents]);
              if (
                parseInt(fieldValues) <= parseInt(dependentValue) &&
                (fieldValue === false ||
                  fieldValue === "" ||
                  fieldValue === undefined)
              ) {
                emptyRequiredFields.push({
                  name,
                  label: label || name,
                  error: error || `${label || name} is required`,
                });
              }
            } else {
              if (required) {
                let isValid = true;

                switch (format) {
                  case "email":
                    isValid =
                      !fieldValue ||
                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fieldValue);
                    break;
                  case "String":
                    if (format === "String") {
                      if (required) {
                        let isValid = true;

                        if (type === "cascade") {
                          try {
                            if (
                              fieldValue === '{"cascadervalue":[],"value":{}}'
                            ) {
                              isValid = false;
                            } else {
                              const parsedValue = JSON.parse(fieldValue);
                              const hasNonChildSelected =
                                isAtLeastOneNonChildSelected(
                                  options,
                                  parsedValue.cascadervalue
                                );

                              if (hasNonChildSelected) {
                                isValid = true;
                              } else {
                                isValid = isAtLeastOneFieldNonEmpty(
                                  parsedValue.value
                                );
                              }
                            }
                          } catch (e) {
                            isValid = false;
                          }
                        } else {
                          isValid =
                            fieldValue !== undefined && fieldValue !== "";
                        }

                        if (!isValid) {
                          emptyRequiredFields.push({
                            name,
                            label: label || name,
                            error: error || `${label || name} is required`,
                          });
                        }
                      }
                    }
                    break;
                  case "date":
                    isValid = fieldValue !== null && fieldValue !== undefined;
                    break;
                  case "array":
                    isValid =
                      Array.isArray(fieldValue) && fieldValue.length > 0;
                    break;
                  case "boolean":
                    if (required) {
                      isValid = fieldValue === true;
                    }
                    break;
                  default:
                  // isValid = fieldValue !== undefined && fieldValue !== "";
                }

                if (!isValid) {
                  emptyRequiredFields.push({
                    name,
                    label: label || name,
                    error: error || `${label || name} is required`,
                  });
                }
              }
            }
          }
        }
        if (regex && fieldValue) {
          try {
            const pattern = new RegExp(regex);
            if (!pattern.test(fieldValue)) {
              emptyRequiredFields.push({
                name,
                label: label || name,
                error: `${label || name} is invalid`,
              });
            }
          } catch (regexError) {}
        }
        if (type === "MenuList" && options?.[0]?.attribute) {
          const nestedValidation = validateRequiredFields(
            options[0].attribute,
            formData
          );
          emptyRequiredFields.push(...nestedValidation.emptyFields);
        }

        if (type === "Accordion" && options) {
          const nestedValidation = validateRequiredFields(options, formData);
          emptyRequiredFields.push(...nestedValidation.emptyFields);
        }
      }

      return {
        isValid: emptyRequiredFields.length === 0,
        emptyFields: emptyRequiredFields,
      };
    } catch (error) {
      return {
        isValid: false,
        emptyFields: [],
        error: "Validation failed",
      };
    }
  }
  function isAtLeastOneNonChildSelected(options, selectedValues) {
    function buildValueMap(nodes) {
      const map = new Map();
      const parentMap = new Map();

      function traverse(node) {
        if (node.children && node.children.length > 0) {
          parentMap.set(
            node.value,
            node.children.map((child) => child.value)
          );
          node.children.forEach((child) => {
            traverse(child);
          });
        } else {
          map.set(node.value, true);
        }
      }

      nodes.forEach((node) => {
        traverse(node);
      });

      return { map, parentMap };
    }

    const { map: valueMap, parentMap } = buildValueMap(options);

    const hasNonChildSelected = selectedValues.some((value) =>
      valueMap.has(value)
    );

    const hasParentWithoutChildrenSelected = selectedValues.some((value) => {
      if (parentMap.has(value)) {
        const children = parentMap.get(value);
        // Check if none of the children are selected
        return !children.some((child) => selectedValues.includes(child));
      }
      return false;
    });

    return hasNonChildSelected && !hasParentWithoutChildrenSelected;
  }

  function isAtLeastOneFieldNonEmpty(fieldValues) {
    return Object.values(fieldValues).some((value) => value.trim() !== "");
  }
  function groupBySection(fields) {
    const sections = new Map();

    fields.forEach((field) => {
      // Split the name by underscore and get the first part
      const [section] = field.name.split("_");

      if (!sections.has(section)) {
        sections.set(section, []);
      }

      sections.get(section).push({
        ...field,
        section,
      });
    });

    // Convert Map to array of objects
    return Array.from(sections).map(([section, fields]) => ({
      section,
      fields,
    }));
  }
  function getSectionNames(fields) {
    return [...new Set(fields.map((field) => field.name.split("_")[0]))];
  }
  const uniqueKeys = completed
    ? [...new Set(Object.keys(completed).map((elem) => elem.split("_")[0]))]
    : [];
  const urlWindow = window.location;
  function getDataFromStorage(key) {
    let storedData = StorageHelper.get(key);
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (e) {
        console.error("Error parsing stored data", e);
        return null;
      }
    }
    return null;
  }

  let sectionsWithEmptyFields = [];
  try {
    // Get stored data
    const errorsData = getDataFromStorage(`${encounterId}2`);
    const retrievedData = getDataFromStorage(`${encounterId}1`);
    const savedData = getDataFromStorage(`${encounterId}`);
    // Initialize storage if needed
    if (!retrievedData) {
      StorageHelper.set(`${encounterId}1`, JSON.stringify(formikRef));
    }

    // Use existing errors if available
    if (errorsData) {
      sectionsWithEmptyFields = errorsData;
    }

    // Check if form values have changed
    const currentValuesString = JSON.stringify(formikRef);
    const previousFormValues = JSON.stringify(retrievedData);
    const hasChanged = previousFormValues !== currentValuesString;

    if (hasChanged) {
      const handleValidation = async () => {
        try {
          const validation = validateRequiredFields(forms, formikRef);
          StorageHelper.set(`${encounterId}1`, JSON.stringify(formikRef));
          console.log("validation", validation);
          const groupedFields = groupBySection(validation.emptyFields);
          sectionsWithEmptyFields = groupedFields.map((group) => group.section);

          StorageHelper.set(
            `${encounterId}2`,
            JSON.stringify(sectionsWithEmptyFields)
          );
        } catch (error) {
          console.error("Validation error:", error);
          // Optionally handle error state
        }
      };

      handleValidation();
    }
  } catch (error) {
    console.error("Error in form validation:", error);
  }
  return (
    <Box sx={{ width: "100%", paddingLeft: "10px" }}>
      <Stepper
        //  sx={{
        //   "& .MuiStepIcon-root.Mui-active": {
        //     // Handle color based on errors, mandatory data, and filled status
        // color: hasErrors
        //   ? "red"  // If there are errors, color the icon red
        //   : AreAllFieldsEmpty
        //   ? "#808080"  // If all fields are empty, set the color to gray
        //   : !isFilled
        //   ? "#FFA500"  // If fields are not filled, set the color to orange
        //   : colors.PRIMARY,  // If everything is filled and there's no error, set to primary color
        //   },
        // }}
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => {
          const hasComment =
            commentData?.counts &&
            Object.keys(commentData?.counts)?.some(
              (elem) => elem.split("_")[0] === label.key
            );
          const hasErrors =
            submitErrors &&
            Object.keys(submitErrors)?.some(
              (elem) => elem.split("_")[0] === label.key
            );
          let mandatorydata = postProcessData(formikRef)[label.key];

          let AreAllFieldsEmpty = areAllFieldsEmpty(mandatorydata);
          if (
            encountedDataLatestStatus !== 0 ||
            encountedDataLatestStatus === null
          ) {
            AreAllFieldsEmpty = false;
          }
          if (encountedDataLatestStatus === null) {
            AreAllFieldsEmpty = true;
          }
          if (label.key !== "MedicationRequest") {
            return (
              <Step
                key={label.key}
                completed={completed[index + 1]}
                sx={{
                  // Default inactive state
                  "& .MuiStepIcon-root": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                    // Default inactive color
                    transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1)", // Smooth transition for color
                  },
                  // Active step color
                  "& .MuiStepIcon-root.Mui-active": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                  },
                  // Completed step color
                  "& .MuiStepIcon-root.Mui-completed": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                  },
                  // Disabled step color
                  "& .MuiStepIcon-root.Mui-disabled": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                  },
                  // Optionally, if you need to target a specific step (using the dynamically generated class):
                  "& .css-676sip-MuiStep-root .MuiStepIcon-root": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY, // Just an example to target a specific step's icon
                  },
                }}
              >
                <StepButton
                  color="inherit"
                  onClick={handleStep(index, label.key)}
                >
                  <StepLabel
                    StepIconProps={{
                      icon:
                        index === 0 ? (
                          urlWindow.pathname.split("/")[3] === "Edit" ? (
                            <AssignmentIndIcon />
                          ) : (encountedData?.["basicVisit_typeofvisit"] ===
                              "" &&
                              urlWindow.pathname.split("/")[3] ===
                                "newVisit") ||
                            encountedData?.["basicVisit_typeofvisit"] ===
                              "Follow Up Visit" ? (
                            uniqueKeys.includes(label.key) ? (
                              index + 1
                            ) : !AreAllFieldsEmpty ? (
                              <CheckCircleIcon
                                style={{
                                  color:
                                    encountedDataLatestStatus !== 0 &&
                                    encountedDataLatestStatus !== 4
                                      ? colors.PRIMARY
                                      : areAllFieldsEmpty(mandatorydata)
                                      ? sectionsWithEmptyFields.length === 0
                                        ? colors.PRIMARY
                                        : sectionsWithEmptyFields.includes(
                                            label.key
                                          ) ||
                                          label.key === "ClinicalExamination"
                                        ? "red"
                                        : (label.key ===
                                            "ParaClinicalInvestigation" &&
                                            (formikRef?.Diagnosis_typeofmg ===
                                              undefined ||
                                              formikRef?.Diagnosis_typeofmg ===
                                                "")) ||
                                          (label.key === "PregnancyHistory" &&
                                            (formikRef?.demography_gender ===
                                              undefined ||
                                              formikRef?.demography_gender ===
                                                ""))
                                        ? "red"
                                        : colors.PRIMARY
                                      : sectionsWithEmptyFields.length === 0
                                      ? colors.PRIMARY
                                      : sectionsWithEmptyFields.includes(
                                          label.key
                                        )
                                      ? "#FFA500"
                                      : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                                  fontSize: "20px",
                                }}
                              />
                            ) : (
                              index + 1
                            )
                          ) : (
                            <AssignmentIndIcon />
                          )
                        ) : encountedData?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit" ? (
                          uniqueKeys.includes(label.key) ? (
                            index + 1
                          ) : !AreAllFieldsEmpty ? (
                            <CheckCircleIcon
                              style={{
                                color:
                                  encountedDataLatestStatus !== 0 &&
                                  encountedDataLatestStatus !== 4
                                    ? colors.PRIMARY
                                    : areAllFieldsEmpty(mandatorydata)
                                    ? sectionsWithEmptyFields.length === 0
                                      ? colors.PRIMARY
                                      : sectionsWithEmptyFields.includes(
                                          label.key
                                        ) || label.key === "ClinicalExamination"
                                      ? "red"
                                      : (label.key ===
                                          "ParaClinicalInvestigation" &&
                                          (formikRef?.Diagnosis_typeofmg ===
                                            undefined ||
                                            formikRef?.Diagnosis_typeofmg ===
                                              "")) ||
                                        (label.key === "PregnancyHistory" &&
                                          (formikRef?.demography_gender ===
                                            undefined ||
                                            formikRef?.demography_gender ===
                                              ""))
                                      ? "red"
                                      : colors.PRIMARY
                                    : sectionsWithEmptyFields.length === 0
                                    ? colors.PRIMARY
                                    : sectionsWithEmptyFields.includes(
                                        label.key
                                      )
                                    ? "#FFA500"
                                    : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                                fontSize: "20px",
                              }}
                            />
                          ) : (
                            index + 1
                          )
                        ) : uniqueKeys.includes(label.key) ? (
                          index
                        ) : !AreAllFieldsEmpty ? (
                          <CheckCircleIcon
                            style={{
                              color:
                                encountedDataLatestStatus !== 0 &&
                                encountedDataLatestStatus !== 4
                                  ? colors.PRIMARY
                                  : areAllFieldsEmpty(mandatorydata)
                                  ? sectionsWithEmptyFields.length === 0
                                    ? colors.PRIMARY
                                    : sectionsWithEmptyFields.includes(
                                        label.key
                                      ) || label.key === "ClinicalExamination"
                                    ? "red"
                                    : (label.key ===
                                        "ParaClinicalInvestigation" &&
                                        (formikRef?.Diagnosis_typeofmg ===
                                          undefined ||
                                          formikRef?.Diagnosis_typeofmg ===
                                            "")) ||
                                      (label.key === "PregnancyHistory" &&
                                        (formikRef?.demography_gender ===
                                          undefined ||
                                          formikRef?.demography_gender === ""))
                                    ? "red"
                                    : colors.PRIMARY
                                  : sectionsWithEmptyFields.length === 0
                                  ? colors.PRIMARY
                                  : sectionsWithEmptyFields.includes(label.key)
                                  ? "#FFA500"
                                  : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          index
                        ),
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          // color: hasErrors
                          //   ? "red"
                          //   : areAllFieldsEmpty(mandatorydata)
                          //   ? "red"
                          //   : !isFilled
                          //   ? "#FFA500"
                          //   : colors.PRIMARY,
                          fontSize: "13px",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {label.label}
                      </span>
                      {hasComment ? (
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#808080",
                            marginLeft: "5px",
                            fontSize: "13px",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          }
          return null;
        })}
      </Stepper>
    </Box>
  );
}
