import { Box, Stack, CircularProgress } from "@mui/material";
import { MgpButton, MgpTable, MgpText, TableConfig } from "../components/global";
import { useEffect, useMemo, useState } from "react";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import { useHospital } from "../hooks/useHospital";
import Loader from "./Loader/Loader";
import { StorageHelper, ENV } from "../helpers";
import { downloadPdf } from "../helpers/dowloadPdf.js";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadExcel } from "../helpers/downloadExcel.js";

export const HospitalsDashboard = ({
  handleHospitalChange = () => {},
  filters,
}) => {
  const { useGetHospitalDashboards } = useHospital();
  const [GetHospitalDashboards, { data, loading, error }] =
    useGetHospitalDashboards;
  const [isLoading, setIsLoading] = useState(true);
  const [mainPageloading, setMainPageloading] = useState(true);
  const memoizedFilters = useMemo(() => filters, [filters]);
  const handleExportData = (type) => {
    const filteredData = table
    .getFilteredRowModel()
    .rows.map((row) => row.original)
    .map((obj) => {
      const filteredObj = {};
      columns.forEach((key) => {
        if (obj.hasOwnProperty(key.accessorKey)) {
          switch(key.accessorKey) {
            case "pc_of_draft_crf":
              filteredObj[key.header] = obj.total_draft_crf && obj.total_draft_crf !== "0"
                ? obj.total_draft_crf + "(" + obj.pc_of_draft_crf + "%" + ")"
                : "0";
              break;
            case "pc_of_ready_to_review_crf":
              filteredObj[key.header] = obj.total_ready_to_review_crf && obj.total_ready_to_review_crf !== "0"
                ? obj.total_ready_to_review_crf + "(" + obj.pc_of_ready_to_review_crf + "%" + ")"
                : "0";
              break;
            case "pc_of_reviewed_crf":
              filteredObj[key.header] = obj.total_reviewed_crf && obj.total_reviewed_crf !== "0"
                ? obj.total_reviewed_crf + "(" + obj.pc_of_reviewed_crf + "%" + ")"
                : "0";
              break;
            case "pc_of_returned_to_doctor_crf":
              filteredObj[key.header] = obj.total_returned_to_doctor_crf && obj.total_returned_to_doctor_crf !== "0"
                ? obj.total_returned_to_doctor_crf + "(" + obj.pc_of_returned_to_doctor_crf + "%" + ")"
                : "0";
              break;
            case "pc_of_re_opened_crf":
              filteredObj[key.header] = obj.total_re_opened_crf && obj.total_re_opened_crf !== "0"
                ? obj.total_re_opened_crf + "(" + obj.pc_of_re_opened_crf + "%" + ")"
                : "0";
              break;
            default:
              filteredObj[key.header] = obj[key.accessorKey];
          }
        }
      });
      return filteredObj;
    });
  let dataToExport = filteredData ? filteredData : data.rows;
  let filename = "Site Details";
  if (dataToExport.length) {
     if (type === "xlsx") {
      downloadExcel(dataToExport,filename)
      // XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
    }else {
      downloadPdf(dataToExport, filename);
    }
  }
  };
  useEffect(() => {
    GetHospitalDashboards({
      variables: {
        filters: memoizedFilters ? memoizedFilters : {},
      },
      fetchPolicy: "network-only",
    });
  }, [GetHospitalDashboards, memoizedFilters]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "site_name", //access nested data with dot notation
        header: "Site Name",
        Cell: ({ row }) => {
          return (
            <Box
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                handleHospitalChange(
                  row.original.hospital_id,
                  row.original.site_name
                );
              }}
            >
              {row.original.site_name}
            </Box>
          );
        },
      },
      {
        accessorKey: "site_code",
        header: "Site Code",
      },
      {
        accessorKey: "region_name", //normal accessorKey
        header: "Region",
      },
      {
        accessorKey: "city_name", //normal accessorKey
        header: "City",
      },
      {
        accessorKey: "doctors", //normal accessorKey
        header: "PI(s)",
      },
      {
        accessorKey: "medical_leads", //normal accessorKey
        header: "Medical Leads",
      },
      {
        accessorKey: "total_patient_registered", //normal accessorKey
        header: "No. of Patients Registered",
      },
      {
        accessorKey: "date_of_first_registration", //normal accessorKey
        header: "Date of First Registration",
      },
      {
        accessorKey: "date_of_last_registration", //normal accessorKey
        header: "Date of Last Registration",
      },
      {
        accessorKey: "pc_of_draft_crf", //normal accessorKey
        header: "No.(%) of Draft CRF",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.total_draft_crf != "0"
                ? row.original.total_draft_crf +
                  "(" +
                  row.original.pc_of_draft_crf +
                  "%" +
                  ")"
                : "0"}
            </>
          );
        },
      },
      {
        accessorKey: "pc_of_ready_to_review_crf", //normal accessorKey
        header: "No.(%) of Ready to Review CRF",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.total_ready_to_review_crf != "0"
                ? row.original.total_ready_to_review_crf +
                  "(" +
                  row.original.pc_of_ready_to_review_crf +
                  "%" +
                  ")"
                : "0"}
            </>
          );
        },
      },
      {
        accessorKey: "pc_of_reviewed_crf", //normal accessorKey
        header: "No.(%) of Reviewed CRF",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.total_reviewed_crf != "0"
                ? row.original.total_reviewed_crf +
                  "(" +
                  row.original.pc_of_reviewed_crf +
                  "%" +
                  ")"
                : "0"}
            </>
          );
        },
      },
      {
        accessorKey: "pc_of_returned_to_doctor_crf", //normal accessorKey
        header: "No.(%) of Returned to Doctor CRF",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.total_returned_to_doctor_crf != "0"
                ? row.original.total_returned_to_doctor_crf +
                  "(" +
                  row.original.pc_of_returned_to_doctor_crf +
                  "%" +
                  ")"
                : "0"}
            </>
          );
        },
      },
      {
        accessorKey: "pc_of_re_opened_crf", //normal accessorKey
        header: "No.(%) of Re-opened CRF",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.total_re_opened_crf != "0"
                ? row.original.total_re_opened_crf +
                  "(" +
                  row.original.total_re_opened_crf +
                  "%" +
                  ")"
                : "0"}
            </>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);

  const user = useMemo(() => JSON.parse(StorageHelper.get("user")), []);
  const assigned_hospitals = useMemo(
    () => JSON.parse(StorageHelper.get("assigned_hospitals")),
    []
  );

  const hospitals = useMemo(() => {
    return assigned_hospitals?.length > 0
      ? data?.GetHospitalDashboards.filter((x) =>
          assigned_hospitals?.includes(x.hospital_id)
        )
      : data?.GetHospitalDashboards;
  }, [data, assigned_hospitals]);

  const table = useMaterialReactTable({
    columns,
    data: hospitals ?? [],
    ...TableConfig,
    enableRowActions: false,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
        <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('xlsx', "table")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('pdf', "table"
              )}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
        </Box>
      </Box>
    ),
    state: { isLoading, columnOrder: columns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      <Stack>
        <MgpText variant="h5">SITE DETAILS</MgpText>
        <MgpTable table={table} />
      </Stack>
    </>
  );
};
