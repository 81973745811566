import { Box, Button, IconButton, MenuItem, Stack ,CircularProgress} from "@mui/material";
import { UpdateSuperRegion } from "./UpdateSuperRegion";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpTable,
  MgpText,
  TableConfig,
} from "../../../components/global";
import { useEffect, useMemo, useState } from "react";
import {  useRbac,useMasterRegion } from "../../../hooks";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../Loader/Loader";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MgpAlertBar from "../../../components/global/MgpAlertBar";

export const SuperRegion = () => {
  const [open, setOpen] = useState(false);
  const [delete_ref, setDeleting] = useState(false);
  const [alertOption,setAlertOption]=useState(false)
  const [message,setMessage]=useState(false)
  const [mainPageloading, setMainPageloading] = useState(true);
  const { useGetMasterRegions, useDeleteMasterRegion } = useMasterRegion();
  const [edit, setEdit] = useState({});
  const { RbacWrapper } = useRbac();
  const [GetMasterRegions, { data, loading, error }] = useGetMasterRegions;
  useEffect(() => {
    console.log("33333",data);
  }, [data]);
  const [isLoading, setIsLoading] = useState(true);
  const [DeleteMasterRegion] = useDeleteMasterRegion;
  const handleNew = () => {
    setEdit({});
    setOpen(true);
  };
  useEffect(() => {
    GetMasterRegions();
  }, [GetMasterRegions]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "master_region_name", //access nested data with dot notation
        header: "Greater Region Name",
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Created On",
      },
    ],
    []
  );
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data,error]);
  const table = useMaterialReactTable({
    columns,
    data: data?.GetMasterRegions ?? [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box>
          <RbacWrapper module={"greaterRegion"} code={"add"}>
            <MgpButton
              variant="contained"
              onClick={handleNew}
              endIcon={<NoteAddIcon sx={{ marginTop: "-3px",fontSize:"16px !important" }}/>}
            >
              Add Greater Region
            </MgpButton>
          </RbacWrapper>
        </Box>
      </Box>
    ),
    renderRowActions: ({ row }) => {
      return (
        <>
          <RbacWrapper module={"greaterRegion"} code={"edit"}>
            <IconButton
              size="small"
              onClick={() => {
                setEdit(row.original);
                console.log("🚀 ~ City ~ row:", row);
                setOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </RbacWrapper>
          <RbacWrapper module={"greaterRegion"} code={"delete"}>
            <IconButton
              size="small"
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </RbacWrapper>
        </>
      );
    },
    state:{ isLoading ,columnOrder:columns.map((e)=>e.accessorKey)}, 
    renderTableBody:() =>{
    return  isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',  
          }}
        >
          <CircularProgress />
        </Box>
      ) : null  
     } 
  });
  const handleSubmitClose=()=>{
    setAlertOption(false)
  }
  const handleDelete = (row) => {
    setDeleting(row.master_region_id);
  };
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      {delete_ref && (
        <AlertDialog
          open={true}
          negative={() => {
            setDeleting(false);
          }}
          positive={(param) => {
            DeleteMasterRegion({ variables: { masterRegionId: Number(param) } ,
            onCompleted:()=>{
                setDeleting(false);
                setMainPageloading(false)
                setAlertOption(true)
                setMessage("Greater Region Removed Successfully")
              }});
          }}
          param={delete_ref}
          title={"Super region Deletion"}
          description={"Are you sure you want to delete this super region?"}
        />
      )}

      <MgpModal
        title={edit.master_region_id ? "Update Greater Region" : "Add Greater Region"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <UpdateSuperRegion
         setMainPageloading={setMainPageloading}
         setAlertOption={setAlertOption}
         setMessage={setMessage}
          edit={edit}
          closed={() => {
            setOpen(false);
          }}
        />
      </MgpModal>

      <Stack >
        <MgpText variant="h5">GREATER REGION LIST</MgpText>
        <MgpTable table={table} />
      </Stack>

      <MgpAlertBar
       open={alertOption}
       setOpen={handleSubmitClose}
       alertType="success"
       message={message} />
    </>
  );
};
