import { ToggleButton, InputLabel, FormHelperText } from "@mui/material";
import { Box, color } from "@mui/system";
import { colors } from "../../theme/colors";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import { useEffect } from "react";
import { RedAsterisk } from "./MgpAccordion";
import { StorageHelper } from "../../helpers";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: "white", // Set the background color for the ToggleButtonGroup
  borderRadius: "5px",
  height: "40px", // Optional: Add border radius for the group
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    borderColor: `${colors.WHITE}`,
    "&:active": {
      backgroundColor: `${colors.PRIMARY_DARK}`,
      color: `${colors.PRIMARY_CONTRAST}`,
    },
    "&.Mui-selected": {
      backgroundColor: `${colors.PRIMARY_DARK}`,
      color: `${colors.PRIMARY_CONTRAST}`,
    },
    "&:hover": {
      backgroundColor: `${colors.PRIMARY_DARK}`,
      color: `${colors.PRIMARY_CONTRAST}`,
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      borderColor: `${colors.GRAY}`, // Change border color for disabled state
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton}`]: {
    marginLeft: -1,
    borderLeft: `1px solid gray`, // Gray line between buttons
    borderRight: `1px solid gray`, // Add right border to middle button
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      borderColor: `${colors.GRAY}`, // Adjust line color when disabled
      borderLeft: `1px solid gray`, // Maintain gray line when disabled
      borderRight: `1px solid gray`, // Maintain gray line when disabled
    },
  },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    borderColor: `${colors.WHITE}`,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    borderLeft: `1px solid gray`, // Gray line for the last button
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      borderColor: `${colors.GRAY}`, // Adjust line color when disabled
      borderLeft: `1px solid gray`, // Maintain gray line when disabled
    },
  },
  [`& .${toggleButtonGroupClasses.firstButton}`]: {
    borderColor: `${colors.WHITE}`,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderRight: `1px solid gray`, // Add right border for first button
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      borderColor: `${colors.WHITE}`, // Adjust border color for disabled state
      borderRight: `1px solid gray`, // Add right border for first button when disabled
    },
  },
}));

export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "& .red-asterisk": {
    color: "red", // Change this to the desired color
    fontSize: "16px",
  },
}));
export const MgpToggleButtonGroup = ({
  disabled,
  splits,
  name,
  label,
  touched,
  errors,
  handleBlur,
  setFieldValue,
  values,
  options,
  defaultValue,
  dependent,
  required,
  attr,
  size,
  viewMode,
  newVisit,
  removeDependence,
  checkfieldvalue,
  setValues,
  commentData,
  encountedDataLatestStatus,
  edit,
  parentName,
  parentType,
  edit_patientform,
  followuplable,
  handleFocus,
  handleChange,
  attribute,
  removedynamicfields,
  encounterid,
}) => {
  const hasAsterisk = label.endsWith("*");
  const onBlur = (event) => {
    if (handleBlur) {
      handleBlur(event);
      // if (handleFocus) {
      //   console.log("gendermale");
      //   handleFocus(event, currentValue);
      // }
    }
  };

  return (
    <>
      <div>
        <CustomInputLabel
          sx={{
            marginBottom: "4px",
            color: Boolean(touched[name] && errors[name])
              ? colors.ERROR
              : colors.CFRBLUE,
            fontFamily: "'Poppins', sans-serif",
            fontSize: "13px",
            fontWeight: "normal",
            whiteSpace: "normal",
            wordBreak: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {values?.["basicVisit_typeofvisit"] === "Follow Up Visit" ? (
            followuplable === undefined ? (
              hasAsterisk ? (
                <>
                  {label?.slice(0, -1)}
                  <RedAsterisk>*</RedAsterisk>
                </>
              ) : (
                label
              )
            ) : hasAsterisk ? (
              <>
                {followuplable?.slice(0, -1)}
                <RedAsterisk>*</RedAsterisk>
              </>
            ) : (
              followuplable
            )
          ) : hasAsterisk ? (
            <>
              {label?.slice(0, -1)}
              <RedAsterisk>*</RedAsterisk>
            </>
          ) : (
            label
          )}
          {/* {hasAsterisk ? (
            <>
              {label?.slice(0, -1)}
              <span className="red-asterisk">*</span>
            </>
          ) : (
            label
          )} */}
        </CustomInputLabel>
        <Box>
          <StyledToggleButtonGroup
            size={size}
            id={name}
            name={name}
            fullWidth
            onBlur={onBlur}
            exclusive={true}
            data-toggle-group-name={name} // Ensure this attribute is set
            onChange={(event, newValue) => {
              handleChange(name, attribute);
              let updatedValues = { ...values };

              if (handleFocus && name === "demography_gender") {
                handleFocus(event, newValue);
              }

              if (
                removeDependence &&
                (newValue === null || newValue !== null)
              ) {
                if (removedynamicfields) {
                  if (checkfieldvalue.includes(newValue)) {
                    if (name === "PregnancyHistory_pregnancyafterdiagnosis") {
                      Object.keys(updatedValues).forEach((key) => {
                        if (
                          key.match(
                            /^PregnancyHistory_pregnancy\d+_ischecked$/
                          ) ||
                          key.match(
                            /^PregnancyHistory_pregnancy_previouspregnancyoutcomes\d+$/
                          ) ||
                          key.match(
                            /^PregnancyHistory_pregnancy_immunosuppressivemedication\d+$/
                          ) ||
                          key.match(
                            /^PregnancyHistory_pregnancy_previouspregnancyoutcomesothers\d+$/
                          )
                        ) {
                          // Extract index from key

                          delete updatedValues[key];
                        }
                      });
                    }
                    if (
                      name ===
                        "Hospitalizations_hospitalizationsrelatedmyastheniagravis" ||
                      name === "Hospitalizations_pasthospitalizedhistory"
                    ) {
                      Object.keys(updatedValues).forEach((key) => {
                        if (
                          key.match(/^Hospitalizations_\d+_ischecked$/) ||
                          key.match(
                            /^Hospitalizations_Whatwasthelengthofeachstayindays\d+$/
                          ) ||
                          key.match(/^Hospitalizations_specifyward\d+$/) ||
                          key.match(
                            /^Hospitalizations_mainreasonformghospitalization\d+$/
                          ) ||
                          key.match(
                            /^Hospitalizations_mainreasonformghospitalizationothers\d+$/
                          ) ||
                          key.match(/^Hospitalizations_underlyingcause\d+$/) ||
                          key.match(
                            /^Hospitalizations_underlyingcauseothers\d+$/
                          )
                        ) {
                          delete updatedValues[key];
                        }
                      });
                    }
                  }
                }
                if (checkfieldvalue.includes(newValue)) {
                  updatedValues[name] = newValue;
                  removeDependence.forEach((nameToRemove) => {
                    updatedValues[nameToRemove] = "";
                  });
                  console.log("updatedValues", updatedValues);
                  StorageHelper.set(encounterid, JSON.stringify(updatedValues));
                  setValues((prevValues) => ({
                    ...prevValues,
                    ...updatedValues,
                  }));
                  handleFocus(event, newValue);
                } else {
                  if (newValue === null) {
                    setFieldValue(name, "");
                  } else {
                    setFieldValue(name, newValue);
                  }
                }
              } else {
                if (newValue === null) {
                  setFieldValue(name, "");
                } else {
                  setFieldValue(name, newValue);
                }
              }
            }}
            value={values[name]}
            variant="outlined"
            aria-label="text alignment"
          >
            {options.map((option, idx) => {
              return (
                <ToggleButton
                  key={idx}
                  disabled={
                    edit_patientform && !disabled
                      ? false
                      : (parentType === "Accordion" &&
                          (encountedDataLatestStatus === 4 ||
                            encountedDataLatestStatus === 6)) ||
                        encountedDataLatestStatus === 4 ||
                        encountedDataLatestStatus === 6
                      ? !edit
                        ? true
                        : disabled
                      : disabled || viewMode
                      ? true
                      : false
                  }
                  value={option}
                  sx={{
                    fontWeight: 500,
                    textTransform: "none",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  {option}
                </ToggleButton>
              );
              // }
            })}
          </StyledToggleButtonGroup>
        </Box>
        <FormHelperText error={Boolean(touched[name] && errors[name])}>
          {/* {touched[name] && errors[name]} */}
        </FormHelperText>
      </div>
    </>
  );
};

export const MgpToggleButtonGroupTab = ({
  options,
  defaultValue,
  value,
  onChange,
}) => {
  return (
    <>
      <div>
        <Box>
          <StyledToggleButtonGroup
            size={"small"}
            fullWidth
            exclusive={true}
            variant="outlined"
            aria-label="text alignment"
            value={value} // Set value here
            onChange={onChange} // Pass onChange handler
          >
            {options.map((option) => {
              return (
                <ToggleButton
                  value={option}
                  sx={{
                    textTransform: "none",
                    fontSize: "32px",
                  }}
                >
                  {option}
                </ToggleButton>
              );
            })}
          </StyledToggleButtonGroup>
        </Box>
      </div>
    </>
  );
};
