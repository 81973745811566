import {
  Button,
  Menu,
  MenuItem,
  Popper,
  Paper,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import { MgpButton } from "./MgpButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colors } from "../../theme/colors";
import { MgpText } from "./MgpText";
import { Link } from "react-router-dom";
import { UpdatePassword } from "../../views/UpdatePassword";
import { MgpDD, MgpModal } from "../../components/global";
import Cookies from "js-cookie";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { UpdateUser } from "../../views/masters/AddUser/UpdateUser";
import Loader from "../../views/Loader/Loader";
import MgpAlertBar from "../../components/global/MgpAlertBar.js";
import { useUser } from "../../hooks/useUsers.js";
import { StorageHelper } from "../../helpers/storageHelper.js";
import { useCode } from "../../hooks/useDownloadCodeBook";
import ExcelJS from "exceljs";
import { ENV } from "../../helpers/configs.js";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import zIndex from "@mui/material/styles/zIndex.js";
import { useMaster } from "../../hooks/useMaster.js";
const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
      "& a": {
        textDecoration: "none",
      },
    },
  },
}));
export const MgpMenu = ({
  options,
  id,
  startIcon,
  variant,
  isActive,
  currentPath,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenmodal] = React.useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const open = Boolean(anchorEl);
  const [alertOption, setAlertOption] = useState(false);
  const [message, setMessage] = useState("");
  const [mainPageloading, setMainPageloading] = useState(false);
  const { useViewUser } = useUser();
  const [ViewUser] = useViewUser;
  const [edit, setEdit] = useState({});

  const handleClose = (option) => {
    if (option?.section?.toLowerCase() === "Logout"?.toLowerCase()) {
      StorageHelper.remove("subinvestigator_id");
      StorageHelper.remove("token");
      StorageHelper.remove("default_page");
      StorageHelper.remove("sub_investigator");
      StorageHelper.remove("user");
      StorageHelper.remove("default_page");
      StorageHelper.remove("assigned_hospitals");
      StorageHelper.clear();
      // Cookies.remove('userMgpr');
    }

    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [alertType, setAlertType] = useState("");
  const [patientList, setPatientList] = useState("");
  const [openHospitalFilter, setOpenHospitalFilter] = useState(false);
  const [openPatientFilter, setOpenPatientFilter] = useState(false);
  const [dataExtractType, setDataExtractType] = useState("");
  const handleOpenModal = () => setOpenmodal(true);
  const handleCloseModal = () => setOpenmodal(false);
  const handleOpenFilter = () => setOpenHospitalFilter(true);
  const handleCloseFilter = () => setOpenHospitalFilter(false);
  const handleOpenPatient = () => setOpenPatientFilter(true);
  const handleClosePatient = () => setOpenPatientFilter(false);

  const assigned_hospitals = useMemo(
    () => JSON.parse(StorageHelper.get("assigned_hospitals")),
    []
  );
  const { hospitals } = useMaster();
  const hospitalsOption = useMemo(() => {
    const filteredHospitals =
      assigned_hospitals?.length > 0
        ? hospitals.filter((x) => assigned_hospitals?.includes(x.value))
        : hospitals;

    return [{ label: "All Hospitals", value: "0" }, ...filteredHospitals];
  }, [assigned_hospitals, hospitals]);
  const [hospitalSelected, setHospitalSelected] = useState([]);
  const handleSubmitClose = () => {
    setAlertOption(false);
  };
  const {
    usegetLookup,
    usegetLookupcoded,
    usegetLookuprequired,
    usegetLookupCodedrequired,
  } = useCode();
  const [getLookup, { data, loading, error }] = usegetLookup;
  const [
    getLookupCoded,
    {
      data: getLookupCodedData,
      loading: getLookupcodedLoading,
      error: getLookupCodedError,
    },
  ] = usegetLookupcoded;

  const [
    getLookupRequired,
    {
      data: getLookupRequiredData,
      loading: getLookupRequiredLoading,
      error: getLookupRequiredError,
    },
  ] = usegetLookuprequired;
  const [
    getLookupCodedRequired,
    {
      data: getLookupCodedRequiredData,
      loading: getLookupCodedRequiredLoading,
      error: getLookupCodedRequiredError,
    },
  ] = usegetLookupCodedrequired;
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const downloaddatacoded = (hospitalSelected = "", self = true) => {
    setMainPageloading(true);
    setOpenHospitalFilter(false);
    setOpenPatientFilter(false);

    getLookupCoded({
      fetchPolicy: "cache-and-network",
      variables: {
        hospital_id:
          hospitalSelected == [0]
            ? [0]
            : hospitalSelected
            ? hospitalSelected
            : [parseInt(hospitalsOption[0]?.value)],
        self: self == "other" ? false : true,
      },
      onCompleted: (data) => {
        if (data?.getLookupCoded?.length > 0) {
          // Initialize a new workbook and worksheet
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");

          const allKeys = [];
          // Collect all keys in the order they first appear
          data.getLookupCoded.forEach((row) => {
            Object.keys(row).forEach((key) => {
              if (!allKeys.includes(key)) {
                allKeys.push(key);
              }
            });
          });

          // Add headers to worksheet and set them to bold
          const headerRow = worksheet.addRow(allKeys);
          headerRow.font = { bold: true, size: 12 }; // Set header row font to bold

          // Add data rows to worksheet
          data.getLookupCoded.forEach((row) => {
            const rowData = allKeys.map((key) => {
              if (Array.isArray(row[key])) {
                // Handle arrays by creating a new column for each value
                return row[key]
                  .map((value) => (value === undefined ? "" : value))
                  .join(", ");
              } else {
                return row[key] === undefined ? "" : row[key];
              }
            });
            worksheet.addRow(rowData);
          });

          // Center align all cells
          worksheet.eachRow((row) => {
            row.alignment = { horizontal: "center", vertical: "center" };
          });

          // Adjust column widths
          worksheet.columns.forEach((column) => {
            const maxLength = column.values.reduce((max, value) => {
              return Math.max(max, value ? value.toString().length : 0);
            }, 0);
            column.width = maxLength + 2; // Add some padding
          });

          // Create a Blob and download the file
          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "MG Coded Full Data Set.xlsx";
            a.click();
            URL.revokeObjectURL(url);
            setMainPageloading(false);
          });
        } else {
          setMainPageloading(false);
          setAlertOption(true);
          setMessage("No records to extract");
          setAlertType("error");
        }
      },
    });
  };

  const downloaddatanone = (hospitalSelected = "", self = true) => {
    setMainPageloading(true);
    setOpenHospitalFilter(false);
    setOpenPatientFilter(false);

    getLookup({
      fetchPolicy: "cache-and-network",
      variables: {
        hospital_id:
          hospitalSelected == [0]
            ? [0]
            : hospitalSelected
            ? hospitalSelected
            : [parseInt(hospitalsOption[0]?.value)],
        self: self == "other" ? false : true,
      },
      onCompleted: (data) => {
        if (data?.getLookup?.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");

          // Collect all keys in the order they first appear
          const allKeys = [];
          data.getLookup.forEach((row) => {
            Object.keys(row).forEach((key) => {
              if (!allKeys.includes(key)) {
                allKeys.push(key);
              }
            });
          });

          // Add headers to worksheet and set them to bold
          const headerRow = worksheet.addRow(allKeys);
          headerRow.font = { bold: true, size: 12 }; // Set header row font to bold

          // Add data rows to worksheet
          data.getLookup.forEach((row) => {
            const rowData = allKeys.map((key) => {
              if (Array.isArray(row[key])) {
                // Handle arrays by creating a new column for each value
                return row[key]
                  .map((value) => (value === undefined ? "" : value))
                  .join(", ");
              } else {
                return row[key] === undefined ? "" : row[key];
              }
            });
            worksheet.addRow(rowData);
          });

          // Center align all cells
          worksheet.eachRow((row) => {
            row.alignment = { horizontal: "center", vertical: "center" };
          });

          // Adjust column widths
          worksheet.columns.forEach((column) => {
            const maxLength = column.values.reduce((max, value) => {
              return Math.max(max, value ? value.toString().length : 0);
            }, 0);
            column.width = maxLength + 2; // Add some padding
          });

          // Create a Blob and download the file
          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "MG Non-Coded Full Data Set.xlsx";
            a.click();
            URL.revokeObjectURL(url);
            setMainPageloading(false);
          });
        } else {
          setMainPageloading(false);
          setAlertOption(true);
          setMessage("No records to extract");
          setAlertType("error");
        }
      },
    });
  };
  const downloaddatarequirednone = (hospitalSelected = "", self = true) => {
    setMainPageloading(true);
    setOpenHospitalFilter(false);
    setOpenPatientFilter(false);

    getLookupRequired({
      fetchPolicy: "cache-and-network",
      variables: {
        hospital_id:
          hospitalSelected == [0]
            ? [0]
            : hospitalSelected
            ? hospitalSelected
            : [parseInt(hospitalsOption[0]?.value)],
        self: self == "other" ? false : true,
      },
      onCompleted: (data) => {
        if (data?.getLookupRequired?.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");

          // Collect all keys in the order they first appear
          const allKeys = [];
          data.getLookupRequired.forEach((row) => {
            Object.keys(row).forEach((key) => {
              if (!allKeys.includes(key)) {
                allKeys.push(key);
              }
            });
          });

          // Add headers to worksheet
          worksheet.addRow(allKeys).font = { bold: true, size: 12 }; // Set headers to bold

          // Add data rows to worksheet
          data.getLookupRequired.forEach((row) => {
            const rowData = allKeys.map((key) => {
              if (Array.isArray(row[key])) {
                return row[key]
                  .map((value) => (value === undefined ? "" : value))
                  .join(", ");
              } else {
                return row[key] === undefined ? "" : row[key];
              }
            });
            worksheet.addRow(rowData);
          });

          // Center align all cells
          worksheet.eachRow((row) => {
            row.alignment = { horizontal: "center", vertical: "center" };
          });

          // Adjust column widths
          worksheet.columns.forEach((column) => {
            const maxLength = column.values.reduce((max, value) => {
              return Math.max(max, value ? value.toString().length : 0);
            }, 0);
            column.width = maxLength + 2; // Add some padding
          });

          // Create a Blob and download the file
          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "MG Non-Coded Mandatory Data.xlsx";
            a.click();
            URL.revokeObjectURL(url);
            setMainPageloading(false);
          });
        } else {
          setMainPageloading(false);
          setAlertOption(true);
          setMessage("No records to extract");
          setAlertType("error");
        }
      },
    });
  };

  const downloaddatarequired = (hospitalSelected = "", self = true) => {
    setMainPageloading(true);
    setOpenHospitalFilter(false);
    setOpenPatientFilter(false);

    getLookupCodedRequired({
      fetchPolicy: "cache-and-network",
      variables: {
        hospital_id:
          hospitalSelected == [0]
            ? [0]
            : hospitalSelected
            ? hospitalSelected
            : [parseInt(hospitalsOption[0]?.value)],
        self: self == "other" ? false : true,
      },
      onCompleted: (data) => {
        if (data?.getLookupCodedRequired?.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");

          // Collect all keys in the order they first appear
          const allKeys = [];
          data.getLookupCodedRequired.forEach((row) => {
            Object.keys(row).forEach((key) => {
              if (!allKeys.includes(key)) {
                allKeys.push(key);
              }
            });
          });

          // Add headers to worksheet and set them to bold
          const headerRow = worksheet.addRow(allKeys);
          headerRow.font = { bold: true, size: 12 }; // Set header row font to bold

          // Add data rows to worksheet
          data.getLookupCodedRequired.forEach((row) => {
            const rowData = allKeys.map((key) => {
              if (Array.isArray(row[key])) {
                // Handle arrays by creating a new column for each value
                return row[key]
                  .map((value) => (value === undefined ? "" : value))
                  .join(", ");
              } else {
                return row[key] === undefined ? "" : row[key];
              }
            });
            worksheet.addRow(rowData);
          });

          // Center align all cells
          worksheet.eachRow((row) => {
            row.alignment = { horizontal: "center", vertical: "center" };
          });

          // Adjust column widths
          worksheet.columns.forEach((column) => {
            const maxLength = column.values.reduce((max, value) => {
              return Math.max(max, value ? value.toString().length : 0);
            }, 0);
            column.width = maxLength + 2; // Add some padding
          });

          // Create a Blob and download the file
          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "MG Coded Mandatory Data.xlsx";
            a.click();
            URL.revokeObjectURL(url);
            setMainPageloading(false);
          });
        } else {
          setMainPageloading(false);
          setAlertOption(true);
          setMessage("No records to extract");
          setAlertType("error");
        }
      },
    });
  };
  const isParentActive = options?.some(
    (subOption) => subOption.link === currentPath
  );
  const [nestedMenuOpen, setNestedMenuOpen] = useState(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const commonStyle = {
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    zIndex: 800,
    color: colors.SECONDARY_TEXT,
  };

  const handleDownload = (type, option) => {
    handleClose(option);
    switch (type) {
      case "Coded Full Data Set":
        downloaddatacoded();
        break;
      case "Non-Coded Full Data Set":
        downloaddatanone();
        break;
      case "Non-Coded Mandatory Data":
        downloaddatarequirednone();
        break;
      case "Coded Mandatory Data":
        downloaddatarequired();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {mainPageloading && (
        <Loader
          style={{
            position: "absolute", // Ensure it overlays correctly
            top: 0, // Adjust as necessary
            left: 0, // Adjust as necessary
            zIndex: 1000, // Ensure this is lower than the menu
          }}
          open={mainPageloading}
        />
      )}

      <Button
        id={`${id}-button`}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant={variant}
        disableElevation
        onClick={handleClick}
        //onMouseOver={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={startIcon}
        sx={{
          textTransform: "capitalize",
          fontSize: "16px",
          fontWeight: isParentActive ? "bold" : 500,
          borderBottom: isParentActive ? `2px solid ${colors.PRIMARY}` : "none",
        }}
      >
        {id}
      </Button>

      <StyledMenu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, idx) => {
          const isSubOptionActive = option.link === currentPath;

          // Handle nested options
          if (option.option) {
            return (
              <React.Fragment key={idx}>
                <MenuItem
                  onClick={(event) => {
                    setNestedMenuOpen(nestedMenuOpen === idx ? null : idx);
                    setNestedAnchorEl(
                      nestedMenuOpen === idx ? null : event.currentTarget
                    );
                  }}
                  disableRipple
                  style={{
                    ...commonStyle,
                    zIndex: 800,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MgpText>{option.section}</MgpText>
                  <KeyboardArrowRightIcon style={{ marginLeft: 8 }} />
                </MenuItem>

                {/* Nested Menu */}
                <Popper
                  open={nestedMenuOpen === idx}
                  anchorEl={nestedAnchorEl}
                  placement="right-start" // Position the submenu to the right
                  disablePortal={false}
                  style={{ zIndex: 80000 }} // Adjust as necessary for stacking
                >
                  <Paper>
                    {option.option.map((subOption, subIdx) => (
                      <MenuItem
                        key={subIdx}
                        onClick={() => {
                          setDataExtractType(subOption.section);
                          if (
                            JSON.parse(StorageHelper.get("user"))?.role_id ===
                            parseInt(ENV.doctor_role_id)
                          )
                            handleDownload(subOption.section);
                          else {
                            if (
                              JSON.parse(StorageHelper.get("user"))?.role_id ===
                              parseInt(ENV.medical_lead_role_id)
                            )
                              setOpenPatientFilter(true);
                            else setOpenHospitalFilter(true);
                          }
                        }}
                        disableRipple
                        style={commonStyle}
                      >
                        {subOption.section}
                      </MenuItem>
                    ))}
                  </Paper>
                </Popper>
              </React.Fragment>
            );
          }

          // Render regular options
          if (option.section === "UpdatePassword") {
            return (
              <MenuItem
                key={idx}
                onClick={handleOpenModal}
                disableRipple
                style={commonStyle}
              >
                <MgpText>Change Password</MgpText>
              </MenuItem>
            );
          } else if (option.section === "UpdateProfile") {
            return (
              <MenuItem
                key={idx}
                onClick={() =>
                  ViewUser({
                    variables: {
                      userId: JSON.parse(StorageHelper.get("user"))?.user_id,
                    },
                    fetchPolicy: "cache-and-network",
                    onCompleted: (data) => {
                      setEdit(data?.ViewUser);
                      setOpenProfile(true);
                    },
                  })
                }
                disableRipple
                style={commonStyle}
              >
                <MgpText>Update My Information</MgpText>
              </MenuItem>
            );
          } else if (option.section === "Code Book") {
            return (
              <MenuItem key={idx} disableRipple>
                <a
                  style={{
                    ...commonStyle,
                    textDecoration: "none",
                  }}
                  href={`${ENV.file_download_url}/code book/MGPR Code Book.xlsx`}
                  download="test"
                >
                  {option.section}
                </a>
              </MenuItem>
            );
          }

          // Handle single download options
          if (
            [
              "Coded Full Data Set",
              "Non-Coded Full Data Set",
              "Non-Coded Mandatory Data",
              "Coded Mandatory Data",
            ].includes(option.section)
          ) {
            return (
              <MenuItem
                key={idx}
                onClick={() => handleDownload(option.section)}
                disableRipple
                style={commonStyle}
              >
                {option.section}
              </MenuItem>
            );
          }

          // Default menu item
          return (
            <MenuItem
              key={idx}
              onClick={() => handleClose(option)}
              disableRipple
              component={Link}
              style={{
                ...commonStyle,
                fontWeight: isSubOptionActive ? "bold" : "500",
              }}
              to={option.link}
            >
              {option.section}
            </MenuItem>
          );
        })}
      </StyledMenu>

      <MgpModal
        styling={{ width: "40vw", overflow: "auto" }}
        open={openModal}
        handleOpen={handleOpenModal}
        onClose={handleCloseModal}
        title={"Change Password"}
      >
        <UpdatePassword
          handleClose={handleCloseModal}
          setAlertOption={setAlertOption}
          setMessage={setMessage}
          setAlertType={setAlertType}
        ></UpdatePassword>
      </MgpModal>

      <MgpModal
        title={"Update Profile"}
        open={openProfile}
        onClose={() => {
          setOpenProfile(false);
        }}
      >
        <UpdateUser
          setAlertOption={setAlertOption}
          setMainPageloading={setMainPageloading}
          setMessage={setMessage}
          setAlertType={setAlertType}
          edit={edit}
          closed={() => {
            setOpenProfile(false);
          }}
          self={true}
        />
      </MgpModal>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
      <MgpModal
        styling={{ width: "35%", maxHeight: "80vh" }}
        open={openHospitalFilter}
        handleOpen={handleOpenFilter}
        onClose={handleCloseFilter}
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <MgpText variant="h4">Select Hospital(s)</MgpText>
          </Grid>

          <Grid item md={12}>
            <MgpDD
              label="Select"
              variant="outlined"
              size="large"
              name="hospital_id"
              fullWidth
              onChange={(e) => {
                let selectedValues = e.target.value.map(Number);
                if (selectedValues.includes(0)) {
                  // If "All Hospitals" (value: 0) is selected, keep only "All Hospitals"
                  selectedValues = [0];
                } else {
                  // If other hospitals are selected, remove "All Hospitals" if it was selected
                  selectedValues = selectedValues.filter(
                    (value) => value !== 0
                  );
                }

                setHospitalSelected(selectedValues);
              }}
              options={hospitalsOption}
              value={hospitalSelected.map(String)}
              multiselect={true}
            />
          </Grid>

          <Grid item md={12} sx={{ marginTop: "16px" }}>
            <MgpButton
              endIcon={<FileDownloadIcon />}
              variant="contained"
              fullWidth
              onClick={() => {
                // const hospitalSelectedInt = hospitalSelected.map(Number);
                switch (dataExtractType) {
                  case "Coded Full Data Set":
                    downloaddatacoded(hospitalSelected);
                    break;
                  case "Non-Coded Full Data Set":
                    downloaddatanone(hospitalSelected);
                    break;
                  case "Non-Coded Mandatory Data":
                    downloaddatarequirednone(hospitalSelected);
                    break;
                  case "Coded Mandatory Data":
                    downloaddatarequired(hospitalSelected);
                    break;
                  default:
                    break;
                }
              }}
            >
              Extract Data
            </MgpButton>
          </Grid>
        </Grid>
      </MgpModal>

      <MgpModal
        styling={{ width: "35%", maxHeight: "80vh" }}
        open={openPatientFilter}
        handleOpen={handleOpenPatient}
        onClose={handleClosePatient}
      >
        {/* <Stack spacing={3}> */}
        <Grid container spacing={2}>
          <Grid item md={12}>
            <MgpText variant="h4">Select Patient List</MgpText>
          </Grid>
          <Grid item md={12}>
            <MgpDD
              label="Select"
              variant="outlined"
              size="large"
              name="self"
              fullWidth
              onChange={(e) => {
                setPatientList(e.target.value);
              }}
              options={[
                { label: "My Patient", value: "self" },
                { label: "Hospital Patient", value: "other" },
              ]}
              value={patientList}
              multiselect={false}
            />
          </Grid>

          <Grid item md={12} sx={{ marginTop: "16px" }}>
            <MgpButton
              endIcon={<FileDownloadIcon />}
              variant="contained"
              fullWidth
              onClick={() => {
                downloaddatacoded("", patientList);
                switch (dataExtractType) {
                  case "Coded Full Data Set":
                    downloaddatacoded("", patientList);
                    break;
                  case "Non-Coded Full Data Set":
                    downloaddatanone("", patientList);
                    break;
                  case "Non-Coded Mandatory Data":
                    downloaddatarequirednone("", patientList);
                    break;
                  case "Coded Mandatory Data":
                    downloaddatarequired("", patientList);
                    break;
                  default:
                    break;
                }
              }}
            >
              Extract Data
            </MgpButton>
            {/* <MgpBackdrop
                  openBackdrop={false}
                  message={"Updating..."}
                /> */}
          </Grid>
        </Grid>
        {/* </Stack> */}
      </MgpModal>
    </>
  );
};
