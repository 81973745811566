import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoneIcon from "@mui/icons-material/Done";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tooltip,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AlertDialog,
  MgpButton,
  MgpDD,
  MgpModal,
  MgpRadioButton,
  MgpTable,
  MgpTableStatus,
  MgpText,
  TableConfig,
} from "../../components/global";
import MgpAlertBar from "../../components/global/MgpAlertBar";
import StatusColors from "../../components/global/StatusColors";
import { ENV, StorageHelper } from "../../helpers";
import { downloadPdf } from "../../helpers/dowloadPdf";
import { useMaster, useRbac } from "../../hooks";
import { useTransfer } from "../../hooks/useTransfer";
import { colors } from "../../theme/colors";
import Loader from "../Loader/Loader";
import { RejectForm } from "./RejectForm";
import { TransferForm } from "./TransferForm";
import { localDate } from "../../components/global/MgpNotification";
import { downloadExcel } from "../../helpers/downloadExcel";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  useKeysAsHeaders: true,
});

export const TransferPatient = () => {
  const userDetails = JSON.parse(StorageHelper.get("user"));
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [reject, setOpenReject] = useState(false);
  const [approve, setApprove] = useState(false);
  const [alertOption, setAlertOption] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const {
    useAddTransfer,
    useGetTransfer,
    useUpdateTransfer,
    useGetTransferByHospital,
    useGetTransferOverview,
    useGetTransferIn,
    useGetTransferOut,
  } = useTransfer();
  const [edit, setEdit] = useState({});
  const [Update_Transfer] = useUpdateTransfer;
  const [GetTransferByHospital, { data: getTransferHospital }] =
    useGetTransferByHospital;
  const [GetOverViewTransfer, { data: getOverviewData }] =
    useGetTransferOverview;
  const [mainPageloading, setMainPageloading] = useState(true);
  const [GetTransferIn, { data: transferIn }] = useGetTransferIn;
  const [GetTransferOut, { data: transferOut }] = useGetTransferOut;
  const { RbacWrapper, rbac } = useRbac();
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("1");
  const assigned_hospitals = useMemo(
    () => JSON.parse(StorageHelper.get("assigned_hospitals")),
    []
  );
  const { hospitals } = useMaster();
  const hospitalsOption = useMemo(() => {
    const filteredHospitals =
      assigned_hospitals?.length > 0
        ? hospitals.filter((x) => assigned_hospitals?.includes(x.value))
        : hospitals;

    return [{ label: "All Hospitals", value: "all" }, ...filteredHospitals];
  }, [assigned_hospitals, hospitals]);
  const [hospitalSelected, setHospitalSelected] = useState(
    hospitalsOption[0]?.value
  );
  const handleNew = () => {
    setEdit({});
    setOpen(true);
  };
  const statuses = [
    {
      label:
        "Pending - This status indicates that the patient transfer process is still ongoing and has not been finalized.",
      value: "1",
      color: "yellow",
    },
    {
      label:
        "Accepted - This status indicates that the new site has approved the patient transfer process.",
      value: "2",
    },
    {
      label:
        "Rejected - This status indicates that the new site has declined or refused the patient transfer process.",
      value: "3",
    },
  ];
  const navigate = useNavigate();
  const handleExportData = (type) => {
    let filteredData = [];
    let filename = "";
    filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map((obj) => {
        const filteredObj = {};
        filteredColumns.forEach((key) => {
          if (obj.hasOwnProperty(key.accessorKey)) {
            if (key.accessorKey === "consent_date") {
              filteredObj[key.header] = localDate(obj[key.accessorKey]).split(
                ","
              )[0];
            } else if (
              (key.accessorKey === "new_registry_no" &&
                obj[key.accessorKey] == "") ||
              (key.accessorKey === "approved_on" && !obj[key.accessorKey]) ||
              (key.accessorKey === "status_comment" && !obj[key.accessorKey])
            ) {
              filteredObj[key.header] = "N/A";
            } else {
              filteredObj[key.header] = obj[key.accessorKey];
            }
          }
        });
        return filteredObj;
      });
    if (value == 1) {
      filename = "Transfer_Patients_New_Patients";
    } else if (value == 2) {
      filename = "Transfer_Patients_Old_Patients";
    } else {
      filename = "Overview of All Hospitals";
    }

    let dataToExport = filteredData ? filteredData : [];

    if (dataToExport.length) {
      if (type === "xlsx") {
        downloadExcel(dataToExport, filename)
      } else {
        downloadPdf(dataToExport, filename);
      }
    }
  };
  const [value, setValue] = useState(
    parseInt(userDetails?.role_id) === parseInt(ENV.doctor_role_id) ||
      parseInt(userDetails?.role_id) === parseInt(ENV.medical_lead_role_id)
      ? "1"
      : "3"
  );

  const handleViewPatient = (patient_id) => {
    navigate(`/app/patients/viewPatient/${patient_id}`);
  };

  const overviewColumns = [
    {
      accessorKey: "site_number",
      header: "Site Number",
    },
    {
      accessorKey: "hospital_name",
      header: "Hospital Name",
      size: 310,
    },
    {
      accessorKey: "transfers",
      header: "Transfers",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        if (
          row.original.no_of_completed_from !== 0 ||
          row.original.no_of_completed_to !== 0
        )
          return <DoneIcon sx={{ fontSize: "16px", marginLeft: "7px" }} />;
      },
    },
    {
      accessorKey: "no_of_request",
      header: "Number Of Transfer Requests Raised by the Hospital ",
      size: 250,
    },
    {
      accessorKey: "no_of_request_received",
      header: "Number Of Transfer Requests Received",
    },
    {
      accessorKey: "no_of_completed_from",
      header: "Number of Completed Transfers From the Hospital",
      size: 250,
    },
    {
      accessorKey: "no_of_completed_to",
      header: "Number of Completed Transfers To the Hospital",
      size: 250,
    },
    {
      accessorKey: "no_of_completed_in",
      header: "Number of Completed Transfers Within Hospital",
      size: 250,
    },
    {
      accessorKey: "no_of_rejected_request",
      header: "Number of Rejected Transfer Requests",
      size: 250,
    },
    {
      accessorKey: "no_of_pending_request",
      header: "Number of Pending Requests ",
    },
    {
      accessorKey: "avg_time_completion",
      header: "Average time for completed transfers to the Hospital (by days)",
      size: 280,
    },
  ];

  const handleApprove = (row) => {
    setApprove(row.id);
  };
  const handleCancel = (row) => {
    setOpenReject(row.id);
  };
  const columns = [
    {
      accessorKey: "old_registry_no",
      header: "Original Registry Number",
      Cell: ({ row }) => {
        let shouldRenderIcon = row.original.transfer_status === 2;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {row.original.old_registry_no}{" "}
            {shouldRenderIcon ? (
              <Tooltip title="patient transferred" arrow>
                <CompareArrowsIcon
                  sx={{ fontSize: "16px", marginLeft: "7px" }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: "new_registry_no",
      header: "New Registry Number",
      Cell: ({ row }) => {
        return row.original.new_registry_no != "" ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "underline",
                color: colors.PRIMARY,
                cursor: "pointer",
              }}
              onClick={() => {
                handleViewPatient(row?.original?.patient_id);
              }}
            >
              {row.original.new_registry_no}{" "}
            </Box>
          </>
        ) : (
          <>N/A</>
        );
      },
    },
    {
      accessorKey: "patient_name",
      header: "Patient Name",
    },
    {
      accessorKey: "national_id",
      header: "National ID",
    },
    {
      accessorKey: "mrn_no",
      header: "MRN",
    },
    {
      accessorKey: "enrollment_date",
      header: "Date of Patient Enrollment in the Registry",
      size: 210,
    },
    {
      accessorKey: "registration_date",
      header: "Registration Date",
    },
    {
      accessorKey: "request_by",
      header: "Name of the Doctor Raised the Request",
    },
    {
      accessorKey: "current_doctor",
      header: "Name of Old Doctor Patient Transferred from (Doctor 1)",
      size: 250,
    },
    {
      accessorKey: "current_hospital",
      header: "Name of Old Hospital Patient Transferred from (Hospital 1)",
      size: 310,
    },

    {
      accessorKey: "transfer_doctor",
      header: "Name of New Doctor Patient Transferred to (Doctor 2)",
      size: 250,
    },
    {
      accessorKey: "transfer_hospital",
      header: "Name of New Hospital Patient is Transferred to (Hospital 2)",
      size: 310,
    },
    {
      accessorKey: "reason_transfer",
      header: "Reason for Transfer",
    },
    {
      accessorKey: "created_on",
      header: "Date of Raising the Request",
      size: 220,
    },
    {
      accessorKey: "consent_date",
      header: "Date of Signed Request",
      size: 220,
      Cell: ({ row }) => {
        return <>{localDate(row.original.consent_date).split(",")[0]}</>;
      },
    },
    {
      accessorKey: "approved_on",
      header: "Date of Completed Transfer",
      size: 220,
      Cell: ({ row }) => {
        return (
          <>{row.original.approved_on ? row.original.approved_on : "N/A"}</>
        );
      },
    },
    {
      accessorKey: "transfer_status_enum",
      header: "Transfer Status",
      Cell: ({ row }) => {
        return (
          <MgpTableStatus
            value={row.original?.transfer_status}
            label={row.original?.transfer_status_enum}
            colorPending={
              parseInt(row.original?.transfer_status) === parseInt(1)
                ? "yellow"
                : ""
            }
          />
        );
      },
    },

    {
      accessorKey: "transfer_form",
      header: "Transfer Form",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.file_path !== "" ? (
              <Box>
                <Tooltip title="Consent form">
                  <a
                    href={`${ENV.file_download_url}/${row.original.file_path}`}
                    download="test"
                  >
                    <IconButton size="small">
                      <FileDownloadIcon
                        sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                      />
                    </IconButton>
                  </a>
                </Tooltip>
              </Box>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "verbal_consent",
      header: "Was verbal consent obtained from the patient?",
      size: 250,
      // enableColumnFilter: true,
      // Cell: ({ row }) => {
      //   return row.original.verbal_consent ? "Yes" : "No";
      // },
    },
    {
      accessorKey: "status_comment",
      header: "Reason for Rejection",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.status_comment ? row.original.status_comment : "N/A"}
          </>
        );
      },
    },
    {
      accessorKey: "created_by_name",
      header: "Created By",
      size: 210,
    },
    {
      accessorKey: "updated_by_name",
      header: "Approved/Rejected By",
      size: 210,
    },
  ];
  let radioButtonList = [
    { value: "1", label: "My Patient List" },
    { value: "2", label: "Hospital Patient List" },
  ];

  const handleChange = (event, newValue) => {
    setMainPageloading(true);
    setValue(newValue);
    setFilterStatus("1");
    // fetchData();
  };

  const fetchData = () => {
    if (
      parseInt(userDetails?.role_id) === parseInt(ENV.doctor_role_id) ||
      parseInt(userDetails?.role_id) === parseInt(ENV.medical_lead_role_id)
    ) {
      selectItem();
    } else {
      selectItemHospital();
    }
  };
  useEffect(() => {
    setMainPageloading(true);
    setIsLoading(true);
    // setHospitalSelected(updatedHospitals[0]?.value)
  }, []);

  useEffect(() => {
    if (!open && !reject) fetchData();
  }, [value, hospitalSelected, open, reject]);

  const selectItem = (item) => {
    setMainPageloading(true);
    setFilterStatus(item ? item : filterStatus);
    if (value === "3") {
      GetOverViewTransfer({
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          setTableData(data?.GetOverViewTransfer);
          setFilteredColumns(overviewColumns);
          setMainPageloading(false);
          setIsLoading(false);
        },
      });
    } else if (value === "1") {
      if (item == 2) {
        GetTransferIn({
          fetchPolicy: "cache-and-network",
          variables: { self: false },
          onCompleted: (data) => {
            updateColumns(data?.GetTransferIn, "In", false);
          },
        });
      } else {
        GetTransferIn({
          fetchPolicy: "cache-and-network",
          onCompleted: (data) => {
            updateColumns(data?.GetTransferIn, "In");
          },
        });
      }
    } else if (value === "2") {
      if (item == 2) {
        GetTransferOut({
          fetchPolicy: "cache-and-network",
          variables: { self: false },
          onCompleted: (data) => {
            updateColumns(data?.GetTransferOut, "Out", false);
          },
        });
      } else {
        GetTransferOut({
          fetchPolicy: "cache-and-network",
          onCompleted: (data) => {
            updateColumns(data?.GetTransferOut, "Out");
          },
        });
      }
    }
  };

  const selectItemHospital = () => {
    setMainPageloading(true);
    if (value === "3") {
      GetOverViewTransfer({
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          setTableData(data?.GetOverViewTransfer);
          setFilteredColumns(overviewColumns);
          setMainPageloading(false);
          setIsLoading(false);
        },
      });
    } else if (value === "1") {
      GetTransferIn({
        variables: {
          hospital_id:
            hospitalSelected == "all"
              ? 0
              : hospitalSelected
              ? parseInt(hospitalSelected)
              : parseInt(hospitalsOption[0]?.value),
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          setHospitalSelected(
            hospitalSelected ? hospitalSelected : hospitalsOption[0]?.value
          );
          updateColumns(data?.GetTransferIn, "In");
        },
      });
    } else if (value === "2") {
      GetTransferOut({
        variables: {
          hospital_id:
            hospitalSelected == "all"
              ? 0
              : hospitalSelected
              ? parseInt(hospitalSelected)
              : parseInt(hospitalsOption[0]?.value),
        },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          setHospitalSelected(
            hospitalSelected ? hospitalSelected : hospitalsOption[0]?.value
          );
          updateColumns(data?.GetTransferOut, "Out");
        },
      });
    }
  };

  const updateColumns = (data, direction, self = true) => {
    console.log("The filter status value is", self);
    if (direction === "Out") {
      let filterColUser = columns.filter((row) =>
        rbac?.tables?.["transfer_table"]?.includes(row.accessorKey)
      );
      let filteredCols = filterColUser.filter(
        (elem) =>
          elem.accessorKey !== "new_registry_no" &&
          elem.accessorKey !== "current_doctor" &&
          elem.accessorKey !== "current_hospital"
      );
      const updatedColumns = !self
        ? filteredCols
          .filter(
            (elem) =>
              !["mrn_no", "national_id", "patient_name"].includes(
                elem.accessorKey
              )
          )
          .map((elem) =>
            elem.accessorKey === "registration_date"
              ? {
                ...elem,
                header: "Date of Patient Registration in Hospital",
                size: 250,
              }
              : elem
          )
        : filteredCols.map((elem) =>
          elem.accessorKey === "registration_date"
            ? {
              ...elem,
              header: "Date of Patient Registration in Hospital",
              size: 250,
            }
            : elem
        );
      setFilteredColumns(updatedColumns);
    } else if (direction == "In") {
      let filterColUser = columns.filter((row) =>
        rbac?.tables?.["transfer_table"]?.includes(row.accessorKey)
      );
      let filteredCols = filterColUser.filter(
        (elem) =>
          elem.accessorKey !== "transfer_doctor" &&
          elem.accessorKey !== "transfer_hospital"
      );
      const updatedColumnsIn = !self
        ? filteredCols
          .filter(
            (elem) =>
              !["mrn_no", "national_id", "patient_name"].includes(
                elem.accessorKey
              )
          )
          .map((elem) =>
            elem.accessorKey === "registration_date"
              ? {
                  ...elem,
                  header: "Date of Patient Registration in Previous Hospital",
                  size: 250,
                }
              : elem
          )
        : filteredCols.map((elem) =>
          elem.accessorKey === "registration_date"
            ? {
              ...elem,
              header: "Date of Patient Registration in Previous Hospital",
              size: 250,
            }
            : elem
        );
      setFilteredColumns(updatedColumnsIn);
    }

    setTableData(data);
    setMainPageloading(false);
    setIsLoading(false);
  };

  const table = useMaterialReactTable({
    columns: filteredColumns,
    data: tableData,
    ...TableConfig,
    initialState: {
      ...TableConfig.initialState,
      columnPinning: {
        right: ["transfer_status_enum", "mrt-row-actions"],
      },
    },
    enableRowActions: value !== "3" ? true : false,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 100, //make actions column wider
      },
    },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
          {value != 3 ? (
            <Stack spacing={2}>
              <RbacWrapper
                module={"patients"}
                code={"patient_transfer_filter_hospital"}
              >
                <Grid item xs={12}>
                  <MgpDD
                    label="Select Hospital"
                    variant="outlined"
                    size="small"
                    name="hospital_id"
                    autoWidth
                    onChange={(e) => {
                      setIsLoading(true);
                      setHospitalSelected(e.target.value);
                      selectItemHospital();
                    }}
                    options={hospitalsOption}
                    value={hospitalSelected}
                    multiselect={false}
                  />
                </Grid>
              </RbacWrapper>
            </Stack>
          ) : (
            <></>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"patients"} code={"patient_list_medical_lead"}>
            <MgpRadioButton
              value={filterStatus}
              onChange={selectItem}
              list={radioButtonList}
            ></MgpRadioButton>
          </RbacWrapper>
          {filterStatus == "1" ? (
            <RbacWrapper
              module={"patients"}
              code={"patient_transfer_filter_doctor"}
            >
              <MgpButton
                variant="contained"
                onClick={handleNew}
                endIcon={
                  <TransferWithinAStationIcon
                    sx={{ marginTop: "-3px", fontSize: "16px !important" }}
                  />
                }
              >
                Transfer Patient
              </MgpButton>
            </RbacWrapper>
          ) : (
            <></>
          )}

          <RbacWrapper
            module={"patients"}
            code={"export_tranferred_patients"}
          >
            <MgpButton

              onClick={() => handleExportData("xlsx")}
              endIcon={
                <FileDownloadIcon sx={{ fontSize: "16px !important" }} />
              }
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton

              onClick={() => handleExportData("pdf")}
              endIcon={
                <FileDownloadIcon sx={{ fontSize: "16px !important" }} />
              }
              variant="contained"
            >
              Export PDF
            </MgpButton>
          </RbacWrapper>
        </Box>

      </Box>
    ),
    renderRowActions: ({ row }) => {
      if (row.original.transfer_status == 1) {
        //1 means transfer status is Pending
        return (
          <Box>
            <RbacWrapper module={"patients"} code={"patient_transfer_action"}>
              {row.original.user_id == row.original.requested_user_id ? (
                <></>
              ) : parseInt(row.original.user_id) ==
                  parseInt(row.original.transfer_doctor_id) ||
                parseInt(row.original.user_id) ==
                  parseInt(row.original.current_doctor_id) ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Approve transfer">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleApprove(row.original);
                      }}
                    >
                      <LibraryAddCheckIcon
                        sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                      />
                    </IconButton>
                  </Tooltip>

                  <MgpText>/</MgpText>
                  <Tooltip title="Reject transfer">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleCancel(row.original);
                      }}
                    >
                      <CancelPresentationIcon
                        sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                <></>
              )}
            </RbacWrapper>
            {/* <RbacWrapper
              module={"patients"}
              code={"patient_transfer_filter_hospital"}
            > */}
            {parseInt(userDetails?.role_id) !== parseInt(ENV.doctor_role_id) &&
            parseInt(userDetails?.role_id) !==
              parseInt(ENV.medical_lead_role_id) ? (
              <Tooltip title="Transfer history" arrow>
                <IconButton
                  sx={{ padding: "2px" }}
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"transfer/history/" + row?.original?.patient_id}
                >
                  <VisibilityOutlinedIcon
                    sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        );
      } else {
        return (
          <Box sx={{ width: "80px" }}>
            {parseInt(userDetails?.role_id) !== parseInt(ENV.doctor_role_id) &&
            parseInt(userDetails?.role_id) !==
              parseInt(ENV.medical_lead_role_id) ? (
              <Tooltip title="Transfer history" arrow>
                <IconButton
                  sx={{ padding: "2px" }}
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"transfer/history/" + row?.original?.patient_id}
                >
                  <VisibilityOutlinedIcon
                    sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        );
      }
    },
    state: {
      isLoading,
      columnOrder: filteredColumns.map((e) => e.accessorKey),
    },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }

  const handleSubmitClose = () => {
    setAlertOption(false);
  };
  return (
    <>
      {approve && (
        <AlertDialog
          open={true}
          negative={() => {
            setApprove(false);
          }}
          positive={(param) => {
            setMainPageloading(true);
            Update_Transfer({
              variables: {
                id: Number(param),
                transfer_status: 2,
                status_comment: "",
              },
              onCompleted: () => {
                setMainPageloading(false);
                setAlertMessage("Patient Transfer Approved Successfully");
                fetchData();
                setAlertOption(true);
                setApprove(false);
              },
            });
          }}
          param={approve}
          title={"Approve Transfer Request"}
          description={"Are you sure you want to approve?"}
        />
      )}
      <MgpModal
        title={"Transfer Patient Request Form"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <TransferForm
          edit={edit}
          closed={() => {
            setOpen(false);
          }}
        />
      </MgpModal>

      {reject ? (
        <RejectForm
          reject={reject}
          setMainPageloading={setMainPageloading}
          setOpenReject={setOpenReject}
          setAlertMessage={setAlertMessage}
          setAlertOption={setAlertOption}
        />
      ) : (
        <></>
      )}

      <Box sx={{ width: "100%", typography: "body1", marginBottom: "1%"  }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="fullWidth"
            >
              {parseInt(userDetails?.role_id) !==
                parseInt(ENV.doctor_role_id) &&
                parseInt(userDetails?.role_id) !==
                  parseInt(ENV.medical_lead_role_id) && (
                  <Tab
                    label="Overview of All Hospitals"
                    value="3"
                    sx={{
                      fontSize: "12px",
                      fontWeight: value === "3" ? 800 : 700,
                    }}
                  />
                )}
              <Tab
                label="Transfer Patients to Hospital (New Patients)"
                value="1"
                sx={{ fontSize: "12px", fontWeight: value === "1" ? 800 : 700 }}
              />
              <Tab
                label="Transfer Patients from the Hospital (Old Patients)"
                value="2"
                sx={{ fontSize: "12px", fontWeight: value === "2" ? 800 : 700 }}
              />
            </TabList>
            
          </Box>
        </TabContext>
        </Box>
         <Box> 
          {parseInt(userDetails?.role_id) !== parseInt(ENV.doctor_role_id) &&
            parseInt(userDetails?.role_id) !==
            parseInt(ENV.medical_lead_role_id) && value == "3"?(
              // <TabPanel value="3">
              <MgpTable table={table} />
              // </TabPanel>
            ):(<></>)}
          {/* <TabPanel value="1"> */}
          {value == "1" ? (
            <>
              <MgpText variant="h5">NEW PATIENT LIST</MgpText>
              <MgpTable table={table} />
              <StatusColors statuses={statuses} />
            </>
          ) : value == "2" ? (
            <>
              <MgpText variant="h5" >OLD PATIENT LIST</MgpText>
              <MgpTable table={table} />
              <StatusColors statuses={statuses} />
            </>
          ) : (<></>)}

          {/* </TabPanel> */}
          {/* <TabPanel value="2">
            <>
              <MgpText variant="h5">OLD PATIENT LIST</MgpText>
              <MgpTable table={table} />
              <StatusColors statuses={statuses} />
            </>
          </TabPanel> */}
      
      </Box>

      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType="success"
        message={alertMessage}
      />
    </>
  );
};
