import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import { MgpButton } from "./MgpButton";
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/colors";
import CancelIcon from "@mui/icons-material/Cancel";

export function AlertDialog({
  positive = () => {},
  negative = () => {},
  open: defaultOpen = false,
  title,
  description,
  param,
}) {
  const [open, setOpen] = React.useState(defaultOpen);

  const handlePositive = () => {
    setOpen(false);
    positive(param);
  };

  const handleNegative = () => {
    setOpen(false);
    negative();
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "420px", // Adjusted width
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            alignItems: "center",
            paddingRight: "0",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {title}
            </Typography>
          </Box>
          <IconButton
            onClick={handleNegative}
            sx={{
              marginBottom: "30px",
              marginLeft: "24px",
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <CancelIcon
              sx={
                {
                  color: colors.PRIMARY,
                }
              }
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h1"
              sx={{
                // fontWeight: 'bold',
                color: "black",
                fontSize: "16px",
                fontWeight: 500,
                textAlign: "center"
              }}
            >
              {description}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <MgpButton
            onClick={handleNegative}
            variant="contained"
          >
            Cancel
          </MgpButton>

          <MgpButton
            onClick={handlePositive}
            variant="contained"
          >
            Yes
          </MgpButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
