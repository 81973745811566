import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { UpdateCity } from "./UpdateCity";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpTable,
  MgpText,
  TableConfig,
} from "../../../components/global";
import { useEffect, useMemo, useState } from "react";
import { useCity, useRbac } from "../../../hooks";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../Loader/Loader";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MgpAlertBar from "../../../components/global/MgpAlertBar";
import { downloadPdf } from "../../../helpers/dowloadPdf.js";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { downloadExcel } from "../../../helpers/downloadExcel.js";

export const City = () => {
  const [open, setOpen] = useState(false);
  const [alertOption, setAlertOption] = useState(false);
  const [delete_ref, setDeleting] = useState(false);
  const [mainPageloading, setMainPageloading] = useState(true);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { useGetCities, useDeleteCity } = useCity();
  const [edit, setEdit] = useState({});
  const { RbacWrapper } = useRbac();
  const [GetCities, { data, loading, error }] = useGetCities;
  const [isLoading, setIsLoading] = useState(true);
  const [DeleteCity] = useDeleteCity;
  const handleNew = () => {
    setEdit({});
    setOpen(true);
  };
  const handleExportData = (type) => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map((obj) => {
        const filteredObj = {};
        columns.forEach((key) => {
          if (obj.hasOwnProperty(key.accessorKey)) {
            filteredObj[key.header] = obj[key.accessorKey];

          }
        });
        return filteredObj;
      });
    let dataToExport = filteredData ? filteredData : data.rows;
    let filename = "Cities List MG";
    if (dataToExport.length) {
      if (type === "xlsx") {
        downloadExcel(dataToExport, filename)
      } else {
        downloadPdf(dataToExport, filename);
      }
    }
  };
  useEffect(() => {
    GetCities();
  }, [GetCities]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "city_name", //access nested data with dot notation
        header: "City",
      },
      {
        accessorKey: "city_code", //access nested data with dot notation
        header: "City Code",
        size:100,
      },
      {
        accessorKey: "region_name",
        header: "Region",
      },
      {
        accessorKey: "country_name",
        header: "Country",
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Created On",
      },
    ],
    []
  );
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);
  const table = useMaterialReactTable({
    columns,
    data: data?.GetCities ?? [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"city"} code={"add"}>
            <MgpButton
              variant="contained"
              onClick={handleNew}
              endIcon={<NoteAddIcon sx={{ marginTop: "-3px",fontSize:"16px !important" }}/>}
            >
              Add City
            </MgpButton>
          </RbacWrapper>
          <MgpButton
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={() => handleExportData('xlsx', "table")}
            endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
            variant="contained"
          >
            Export Excel
          </MgpButton>
          <MgpButton
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={() => handleExportData('pdf', "table"
            )}
            endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
            variant="contained"
          >
            Export PDF
          </MgpButton>
        </Box>
      </Box>
    ),
    renderRowActions: ({ row }) => {
      return (
        <Box >
          {/* <Stack direction={"row"}> */}
          <RbacWrapper module={"city"} code={"edit"}>
            {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
            <Tooltip title={"Edit City"} arrow>
              <IconButton
                size="small"
                onClick={() => {
                  setEdit(row.original);
                  console.log("🚀 ~ City ~ row:", row);
                  setOpen(true);
                }}
              >
              <EditIcon  sx={{height: "16px"}}/>
              </IconButton>
            </Tooltip>
            {/* </Box> */}
          </RbacWrapper>
          <RbacWrapper module={"city"} code={"delete"}>
            <Tooltip title={"Delete City"} arrow>
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(row.original);
                }}
              >
              <DeleteIcon  sx={{height: "16px"}}/>
              </IconButton>
            </Tooltip>
          </RbacWrapper>
          {/* </Stack> */}
        </Box>
      );
    },
    state: { isLoading ,columnOrder:columns.map((e)=>e.accessorKey)},
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  const handleSubmitClose = () => {
    setAlertOption(false);
  };

  const handleDelete = (row) => {
    setDeleting(row.city_id);
  };
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      {delete_ref && (
        <AlertDialog
          open={true}
          negative={() => {
            setDeleting(false);
          }}
          positive={(param) => {
            setMainPageloading(true);
            DeleteCity({
              variables: { cityId: Number(param) },
              onCompleted: () => {
                setDeleting(false);
                setMainPageloading(false);
                setAlertType("success")
                setAlertOption(true);
                setMessage("City Removed Successfully");
              },
            });
          }}
          param={delete_ref}
          title={"City Deletion"}
          description={"Are you sure you want to delete this city?"}
        />
      )}

      <MgpModal
        title={edit.city_id ? "Update City" : "Add City"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <UpdateCity
          setAlertOption={setAlertOption}
          setMainPageloading={setMainPageloading}
          setMessage={setMessage}
          setAlertType = {setAlertType}
          edit={edit}
          closed={() => {
            setOpen(false);
          }}
        />
      </MgpModal>

      <Stack>
        <MgpText variant="h5">CITY LIST</MgpText>
        <MgpTable table={table} />
      </Stack>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
    </>
  );
};
