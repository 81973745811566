import { colors } from "../../theme/colors";
import { styled } from "@mui/material/styles";
import { TextField, Paper } from "@mui/material";

export const MgpTextbox = styled((props) => (
  <TextField {...props} inputProps={{ ...props.inputProps, autoComplete: "off" }} />
))(({ theme }) => ({
  backgroundColor: "white", // Set the background color for the TextField
  borderRadius: "8px", // Optional: Add corner radius
  "& label": {
    "&.with-asterisk::after": {
      content: '"*"',
      color: "red", // Change this to the desired color
      fontSize: "18px",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white", // Set background for input base
  },
  "& .MuiInputBase-input": {
    backgroundColor: "white", // Set background for the input
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main, // Optional: Change border color
  },
  // Add other styles as needed
}));
