import { Container, Grid, Stack } from "@mui/material";
import {
  MgpButton,
  MgpCheckbox,
  MgpDD,
  MgpTextbox,
  MgpTextboxFormik,
} from "../../../components/global";
import { useState } from "react";
import * as yup from "yup";
import { useCountry } from "../../../hooks";
import { Formik } from "formik";
import Loader from "../../Loader/Loader";
import MgpAlertBar from "../../../components/global/MgpAlertBar";
export const UpdateCountry = ({
  setAlertOption,
  setMainPageloading,
  setMessage,
  setAlertType,
  closed = () => {},
  edit = {},
}) => {
  const {
    country_id = "",
    country_name = "",
    country_code = "",
    is_saudi = false,
    is_gcc = false,
  } = edit;
  const { useAddCountry, useUpdateCountry } = useCountry();
  const [AddCountry] = useAddCountry;
  const [UpdateCountry] = useUpdateCountry;
  const handleSubmitClose = () => {
    setAlertOption(false);
    setMainPageloading(false);
  };
  return (
    <>
      <Container fluid="true">
        <Formik
          initialValues={{
            countryName: country_name,
            countryCode: country_code,
            isSaudi: is_saudi ? "Saudi" : "Non Saudi",
            isGcc: is_gcc ? "Yes" : "No",
          }}
          validationSchema={yup.object().shape({
            countryName: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
            countryCode: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required"),
          })}
          onSubmit={(payload) => {
            console.log("payload", payload);
            setMainPageloading(true);
            if (country_id === "") {
              AddCountry({
                variables: {
                  countryName: payload.countryName,
                  countryCode: payload.countryCode,
                  isSaudi: false, // There will be only 1 country in saudi
                  isGcc: payload.isGcc === "Yes" ? true : false,
                },
                onCompleted: () => {
                  closed();
                  setMainPageloading(false);
                  setAlertType("success");
                  setAlertOption(true);
                  setMessage("New Country Added Successfully");
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message);
                  else setMessage("Something went wrong! Please try again");
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("error");
                },
              });
            } else {
              console.log("update", {
                countryName: payload.countryName,
                countryCode: payload.countryCode,
                isGcc: payload.isGcc === "Yes" ? true : false,
                countryId: Number(country_id),
              });
              UpdateCountry({
                variables: {
                  countryName: payload.countryName,
                  countryCode: payload.countryCode,
                  isGcc: payload.isGcc === "Yes" ? true : false,
                  countryId: Number(country_id),
                },
                onCompleted: () => {
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("success");
                  setMessage("Updated Country Successfully");
                  closed();
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message);
                  else setMessage("Something went wrong! Please try again");
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("error");
                },
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Country Name"
                    name="countryName"
                    value={values.countryName}
                    onChange={(e) => {
                      setFieldValue("countryName", e.target.value);
                    }}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={Boolean(
                      touched["countryName"] && errors["countryName"]
                    )}
                    helperText={touched["countryName"] && errors["countryName"]}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Country Code"
                    name="countryCode"
                    splits={"countryCode".split(".")}
                    value={values.countryCode}
                    onChange={(e) => {
                      setFieldValue("countryCode", e.target.value);
                    }}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={Boolean(
                      touched["countryCode"] && errors["countryCode"]
                    )}
                    helperText={touched["countryCode"] && errors["countryCode"]}
                  />
                </Grid>

                {/* <Grid item md={6}>
                  <MgpDD
                    label="Nationality"
                    variant="outlined"
                    size="small"
                    name="isSaudi"
                    error={Boolean(touched["isSaudi"] && errors["isSaudi"])}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    options={[
                      {
                        "value": "Saudi",
                        "label": "Saudi",
                      },
                      {
                        "value": "Non Saudi",
                        "label": "Non Saudi",
                      },
                    ]}
                    helperText={touched["isSaudi"] && errors["isSaudi"]}
                    onBlur={handleBlur}
                    value={values["isSaudi"]}
                    multiselect={false}
                  />
                </Grid> */}
                <Grid item md={6}>
                  <MgpDD
                    label="GCC country"
                    variant="outlined"
                    size="small"
                    name="isGcc"
                    error={Boolean(touched["isGcc"] && errors["isGcc"])}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    options={[
                      {
                        value: "Yes",
                        label: "Yes",
                      },
                      {
                        value: "No",
                        label: "No",
                      },
                    ]}
                    helperText={touched["isGcc"] && errors["isGcc"]}
                    onBlur={handleBlur}
                    value={values["isGcc"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid item md={12}>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    direction={"row"}
                  >
                    <MgpButton onClick={closed} variant="contained">
                      Cancel
                    </MgpButton>
                    <MgpButton type="submit" variant="contained">
                      {!country_id ? "Save" : "Update"}
                    </MgpButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};
