import { VerifiedUser } from "@mui/icons-material";
import { colors } from "../../theme/colors";
import InfoIcon from "@mui/icons-material/Info";
import CircleIcon from "@mui/icons-material/Circle";

export const MgpTableStatus = ({ value = "1", label, colorPending = "" }) => {
  const enumValue = parseInt(value);
  let color = colors.PRIMARY_TEXT;
  if (colorPending !== "") {
    color = "yellow";
  } else {
    switch (enumValue) {
      case 0:
        color = "#ff3241";
        break;
      case 1:
        color = "#4082C1";
        break;
      case 2:
        color = "#54B196";
        break;
      case 3:
        color = "#E53935";
        break;
      case 4:
        color = "#EDAF68";
        break;
      case 5:
        color = "#4082C1";
        break;
      case 6:
        color = "#666666"; // Return to Quality - Blue
        break;
      default:
        color = colors.PRIMARY_DARK;
    }
  }

  return (
    <div style={{ display: "flex" }}>
      {enumValue == 0 && (
        <InfoIcon sx={{ fontSize: "16px", color: color, marginRight: "5px" }} />
      )}
      {enumValue !== 0 && (
        <CircleIcon sx={{ fontSize: "16px", color: color, marginRight: "5px" }} />
      )}
      <span> {label}</span>
    </div>
  );
};
