import {
  Grid,
  List,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRbac } from "../../hooks";
import {
  generateDynamicComponent,
  getSectionForm
} from "../../hooks/useUtil";
import { colors } from "../../theme/colors";
import MgpComment from "./MgpComment";
import { MgpText } from "./MgpText";
export const MgpMenuList = ({
  options,
  label,
  splits,
  touched,
  errors,
  error,
  values,
  handleBlur,
  obj,
  handleChange,
  setFieldValue,
  viewMode,
  newVisit,
  encountedDataLatestStatus,
  commentData,
  setCommentData,
  setValues,
  setFieldTouched,
  edit,
  patientActive,
  parentSection,
  reopen,
  setserotype,
  setnotemessage,
  setErrors,
  setTouched,
  submitErrors,
  submitTouched,
}) => {
  // console.log("setValues", setValues);

  let params = useParams();
  const { RbacWrapper, getActionRbac } = useRbac();
  const [selectedSection, setSelectedSection] = useState("");
  const [forms, setForms] = useState([]);
  useEffect(() => {
    setSelectedSection(options?.[0]?.sections[0].key);
  }, []);

  useEffect(() => {
    setForms(getSectionForm(selectedSection, options[0].attribute));
  }, [selectedSection]);
 
  return (
    <>
      {/* <Typography variant="h4">{label}</Typography> */}
      <Grid container direction="row">
        <Grid item md={3}>
          <List>
            {options[0]?.sections?.map((row, idx) => {
              const hasComment =
                commentData?.counts &&
                Object.keys(commentData?.counts)?.some(
                  (elem) => elem.split("_")[1] === row.key
                );
              return (
                <ListItemButton
                  key={idx}
                  sx={{
                    marginY: "15px",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontSize: "13px",
                    backgroundColor:
                      selectedSection === row.key
                        ? colors.MENULISTPRIMARY
                        : colors.MENULISTSECONDARY,
                    color: Object.keys(error)
                      ?.map((e) => e.split("_")[1])
                      ?.includes(row.key)
                      ? "red"
                      : "white",
                    "&:hover": {
                      backgroundColor: colors.MENULISTPRIMARY,
                    },
                  }}
                  onClick={() => {
                    setSelectedSection(row.key);
                  }}
                >
                  <ListItemText
                    primary={
                      <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <MgpText variant="body2">{row.label} </MgpText>
                        {hasComment ? (
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#3B82F6",
                              marginLeft: "5px",
                            }}
                          ></div>
                        ) : null}
                      </Stack>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Grid>
        <Grid item md={9} sx={{ paddingTop: "25px", paddingLeft: "15px" }}>
          <Grid container spacing={3} direction="row">
            {forms.map((attr, idx) => {
              let commentComponent = null;
              if (params.encounterId) {
                commentComponent = (getActionRbac(
                  "patients",
                  "patient_comment"
                ) ||
                  getActionRbac("patients", "patient_comment_all")) && (
                  <MgpComment
                    attr={{ ...attr, encounterId: params.encounterId }}
                    // Other props as needed
                    count={commentData?.counts?.[attr.name]}
                    setCommentData={setCommentData}
                    commentData={commentData}
                    showall={true}
                    section={parentSection}
                    disable={
                      !patientActive ||
                      encountedDataLatestStatus === 0 ||
                      (encountedDataLatestStatus === 2 &&
                        getActionRbac("patients", "approval") &&
                        !reopen) ||
                      (encountedDataLatestStatus === 3 &&
                        !getActionRbac("patients", "approval")) ||
                      (encountedDataLatestStatus === 2 &&
                        !getActionRbac("patients", "approval")) ||
                      (encountedDataLatestStatus === 6 &&
                        getActionRbac("patients", "approval")) ||
                      (encountedDataLatestStatus === 1 &&
                        !getActionRbac("patients", "approval")) ||
                      (encountedDataLatestStatus === 5 &&
                        !getActionRbac("patients", "patient_comment_all")) ||
                      (encountedDataLatestStatus === 4 &&
                        !getActionRbac("patients", "patient_comment")) ||
                      getActionRbac("patients", "disable_comment")
                    }
                  />
                );
              }
              return (
                <React.Fragment key={idx}>
                  {generateDynamicComponent(
                    attr,
                    touched,
                    errors,
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    obj,
                    viewMode,
                    newVisit,
                    commentComponent,
                    setFieldTouched,
                    commentData,
                    setCommentData,
                    setValues,
                    encountedDataLatestStatus,
                    edit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    setserotype,
                    setnotemessage,
                    null,
                    setErrors,
                    setTouched,
                    submitErrors,
                    submitTouched
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

// export const MgpMenuList = styled(menuList)(({ isFieldSet }) => ({}));
